import { apiFetchHandler } from '_api/clientFetch';
import type { Run, RunEvent, RunLog } from './types';
import { hosts } from '_api/hosts';
import { toaster } from 'toaster';

type GetRunsParams = {
  missionId: string | number;
  cursor: number;
};

export const getRuns = apiFetchHandler<Run[], GetRunsParams>({
  method: 'GET',
  endpoint: ({ missionId, cursor = 1 }) =>
    `/scripting/mission/${missionId}/runs?cursor=${cursor}`,
  errorMessage: 'Could not fetch runs',
  host: hosts.ops.v0,
});

type LogsParams = {
  missionId: string | number;
  runId: string | number;
};

export const getRunLogs = apiFetchHandler<RunLog[], LogsParams>({
  method: 'GET',
  endpoint: ({ missionId, runId }) =>
    `/scripting/mission/${missionId}/runs/${runId}/logs`,
  errorMessage: 'Could not fetch run logs',
  host: hosts.ops.v0,
});

export const postStopRun = apiFetchHandler<undefined, LogsParams>({
  method: 'POST',
  endpoint: ({ missionId, runId }) =>
    `/scripting/mission/${missionId}/runs/${runId}/stop`,
  errorMessage: 'Could not stop run',
  host: hosts.ops.v0,
});

type PostRunEventParams = {
  missionId: string | number;
  scriptName: string;
};

type PostRunEventBody = RunEvent;

export const postRunRun = apiFetchHandler<
  undefined,
  PostRunEventParams,
  PostRunEventBody
>({
  method: 'POST',
  endpoint: ({ missionId, scriptName }) =>
    `/scripting/mission/${missionId}/scripts/${scriptName}/run`,
  errorMessage: 'Could not post run event',
  host: hosts.ops.v0,
  onSuccess: () => {
    toaster.show({
      message: 'Event sent',
      intent: 'success',
    });
  },
});
