import { useCallback, useEffect, useState } from 'react';
import MailToButton from '_organisms/MailToButton/MailToButton';
import { useProjects } from 'datacosmos/stores/ProjectProvider';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import bigLogo from 'images/logo-centric-name-compact.svg';
import smallLogo from 'images/logo-monogram-black.svg';
import Views from 'datacosmos/components/DatacosmosViews';
import s from 'datacosmos/components/Header/DatacosmosHeader.module.scss';
import { useViewMode } from 'datacosmos/utils/hooks/useViewMode';
import classNames from 'classnames';
import {
  DATACOSMOS_SKIP_HOME,
  ENABLE_SEND_FEEDBACK,
  ENABLE_UPGRADE_USER_ROLE,
  LOGO,
  SHOW_MAIN_APP_TOUR,
} from 'env';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import UpgradeUserPermission from '_organisms/UpgradeUserPermission/UpgradeUserPermission';
import { useTour } from 'datacosmos/stores/TourProvider';
import { tourInitialState } from 'reducers/tour';
import { setTourInformation } from 'actions/tour/action';
import { UserProfileMenu } from 'ui/UserMenu';
import ManageScenarioModal from '../ManageScenarioModal';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
import ROUTES from 'routeConstants';
import { Button, Tooltip } from 'opencosmos-ui';

export const DatacosmosHeader = () => {
  const { translate } = useLocalisation();

  const { sendInfo } = useAnalytics();

  const history = useHistory();
  const location = useLocation();

  const { currentScenario, fetchCurrentProjectItems, userScenarios } =
    useProjects();

  const { isMinimal } = useViewMode();

  const windowSize = useWindowSize();
  const logo = windowSize.isTablet ? smallLogo : bigLogo;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const opsOrDcRedirectPathname = window.location.pathname.startsWith('/data')
    ? '/data'
    : '/ops';

  const urlTo =
    window.location.pathname.startsWith('/ops') ||
    window.location.pathname.startsWith('/data')
      ? opsOrDcRedirectPathname
      : '/';
  const queryParams = new URLSearchParams(window.location.search);
  const showTour = queryParams.get('tour') === 'show';

  const { dispatch } = useTour();

  const handleRestartMainAppTour = () => {
    // Starts the tour manually
    dispatch(setTourInformation('RESTART', tourInitialState));
  };

  const handleShowTourFromQueryParams = useCallback(() => {
    dispatch(setTourInformation('RESTART', tourInitialState));
    history.replace({ pathname: location.pathname });
  }, [history, location.pathname, dispatch]);

  useEffect(() => {
    if (showTour) {
      handleShowTourFromQueryParams();
    }
  }, [showTour, handleShowTourFromQueryParams]);

  const { hasPermission: isAllowedToUpgradeRoleForConida } =
    useCheckPermissions({
      permissions: {
        type: 'global',
        actionScope: 'data:order:create',
      },
    });

  return (
    <div
      className={[
        'datacosmos-header',
        'bg-header-top text-header-top-contrast dark:bg-header-top-dark dark:text-header-top-dark-contrast',
      ].join(' ')}
    >
      <div className="left">
        <div
          onClick={() => {
            if (isMinimal) return;
            sendInfo({
              type: 'Project selection opened',
              action: 'Click',
              item: 'Project selection',
              module: 'DataCosmos',
            });
            setIsOpen(true);
          }}
          data-testid="open-project-modal-button"
        >
          <span
            className={classNames(s.scenarioName, {
              'text-item-contrast dark:text-item-contrast cursor-default':
                isMinimal,
              italic: !currentScenario,
            })}
            data-testid="project-name"
          >
            {userScenarios.find((x) => x.id === currentScenario?.id)
              ? currentScenario?.title
              : translate('datacosmos.header.missingProjectTitle')}
          </span>
        </div>

        <div>
          <Views
            currentScenario={currentScenario}
            fetchCurrentProjectItems={fetchCurrentProjectItems}
          />
        </div>
      </div>
      <div className={['middle', s.middleLogo].join(' ')}>
        <div
          className={['header-logo', s.middleLogo].join(' ')}
          role="presentation"
        >
          <Link to={!DATACOSMOS_SKIP_HOME ? urlTo : ROUTES.DATA_CATALOG}>
            <img
              className={classNames({
                [s.middleLogo]: true,
                'dark:invert': !LOGO,
              })}
              id="logo-image"
              src={LOGO !== '' ? LOGO : logo}
              alt="logo-centric-name-compact.svg"
            />
          </Link>
        </div>
      </div>
      <div className="right">
        {SHOW_MAIN_APP_TOUR && (
          <Tooltip content="View tour" placement="bottom">
            <Button
              icon="Info"
              onPress={() => handleRestartMainAppTour()}
              isMinimal
              size="base"
            />
          </Tooltip>
        )}

        {ENABLE_UPGRADE_USER_ROLE && !isAllowedToUpgradeRoleForConida && (
          <UpgradeUserPermission
            text={translate('datacosmos.header.upgradePermission')}
            openModalByDefault={false}
          />
        )}

        {ENABLE_SEND_FEEDBACK && (
          <MailToButton
            subject={translate('datacosmos.header.sendFeedback')}
            team="datacosmos"
          />
        )}
        <UserProfileMenu />
      </div>
      <ManageScenarioModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        canEscapeKeyClose={true}
        isCloseButtonShown={true}
        canOutsideClickClose={true}
        title={translate('datacosmos.projectsDialog.openProject')}
      />
    </div>
  );
};
