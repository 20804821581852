import toKml from 'tokml';
import shpwrite from '@mapbox/shp-write';
import JSZip from 'jszip';
import type { Layer } from 'datacosmos/entities/layer';
import type { ViewLayer } from '_api/views/types';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';
import { CircleLayer } from 'datacosmos/entities/circleLayer';
import { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { LineLayer } from 'datacosmos/entities/lineLayer';
import { GeoJSONLayer } from 'datacosmos/entities/geojsonLayer';
import { geoJsonFeatureToGeoJsonFeatureCollection } from 'datacosmos/utils/geojson';
import { FieldOfRegardLayer } from 'datacosmos/entities/FieldOfRegardLayer';
import conidaWatermark from 'public/images/datacosmos/conida-logo-98x98.png';
import { LOGO } from 'env';
import OCLogo from 'images/logo-monogram-black.svg';

/**
 * downloadFilUrlAsExtension takes a `fileUrl` and downloads it as `extension` to user's local machine
 * @param fileUrl File url to download, usually in a format `FILE_TYPE;ENCODING, FILE_CONTENTS` e.g. `data:application/zip;base64,UEsDBBQAAAAIA...`
 * @param fileName Desired file name for the file
 * @param extension Desired file extension e.g. `zip`
 */
export const downloadFilUrlAsExtension = (
  fileUrl: string,
  fileName: string,
  extension: string
) => {
  const downloadLink = document.createElement('a');

  downloadLink.href = `data:${fileUrl}`;
  downloadLink.download = `${fileName}.${extension}`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  downloadLink.remove();
};

/**
 * downloadGeojsonAsKml takes an arbitrary `geojson` file and downloads it as `.kml` to user's local machine
 * @param geo GeoJSON object to download
 * @param fileName Desired file name for the `.kml` file
 */
export const downloadGeojsonAsKml = (
  geo: GeoJSON.GeoJSON,
  fileName: string,
  includeWaterMark?: boolean
) => {
  const kml = toKml(geo);
  const kmlString = 'application/vnd.google-earth.kml+xml;charset=utf-8,';

  const modifiedKml = includeWaterMark ? addWatermarktoKml(kml) : kml;
  const kmlConverted = kmlString + encodeURIComponent(modifiedKml);
  downloadFilUrlAsExtension(kmlConverted, fileName, 'kml');
};

/**
 * downloadGeojsonAsShp takes an arbitrary `geojson` file and downloads it as `.zip`
 * containing all the necessary shapefile related files to user's local machine
 * @param geo GeoJSON object to download
 * @param fileName Desired file name for the `.zip` file
 */
export const downloadGeojsonAsShp = async (
  geo: GeoJSON.FeatureCollection,
  fileName: string
) => {
  const shp = (await shpwrite.zip(geo, {
    outputType: 'base64',
    compression: 'DEFLATE',
  })) as string;

  const shpUrl = `application/zip;base64, ${shp}`;
  downloadFilUrlAsExtension(shpUrl, fileName, 'zip');
};

/**
 * downloadGeojsonAsKmz takes an arbitrary `geojson` file and downloads it as `.kmz`
 * containing the zipped kml files to user's local machine
 * @param geo GeoJSON object to download
 * @param fileName Desired file name for the `.kmz` file
 */
export const downloadGeojsonAsKmz = async (
  geo: GeoJSON.GeoJSON,
  fileName: string,
  includeWaterMark?: boolean
) => {
  const kml = toKml(geo);
  const modifiedKml = includeWaterMark ? addWatermarktoKml(kml) : kml;

  // Creates a KMZ file by zipping the KML
  const kmz = new JSZip();
  kmz.file(`${fileName}.kml`, modifiedKml);

  const kmzData = await kmz.generateAsync({ type: 'base64' });
  const kmzURL = `application/vnd.google-earth.kmz;base64, ${kmzData}`;
  downloadFilUrlAsExtension(kmzURL, fileName, 'kmz');
};

/**
 * downloadAOIasGeojson takes an arbitrary `geojson` file and downloads it as `.geojson`
 * @param geo GeoJSON object to download
 * @param fileName Desired file name for the `.geojson` file
 */
export const downloadAOIasGeojson = (
  geo: GeoJSON.GeoJSON,
  fileName: string
) => {
  const geojsonURL = `application/vnd.geo+json;charset=utf-8, ${encodeURIComponent(
    JSON.stringify(geo)
  )}`;

  downloadFilUrlAsExtension(geojsonURL, fileName, 'geojson');
};

const addWatermarktoKml = (kmlData: string) => {
  // Create ScreenOverlay XML for watermark
  const screenOverlay = `
    <ScreenOverlay>
    <name>Watermark</name>
    <Icon>
      <href>${LOGO ? conidaWatermark : OCLogo}</href>
    </Icon>
    <overlayXY x="1" y="0" xunits="fraction" yunits="fraction" />
    <screenXY x="1" y="0" xunits="fraction" yunits="fraction" />
    <size x="0" y="0" xunits="fraction" yunits="fraction" />
      </ScreenOverlay>
  `;

  // Insert the ScreenOverlay before </Document>
  const kmlWithWatermark = kmlData.replace(
    '</Document>',
    `${screenOverlay}</Document>`
  );
  return kmlWithWatermark;
};

export const convertLayersToFeatureCollection = (
  layers: ViewLayer[] | Layer[]
) => {
  const featureCollection: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };
  const layersToDownload = [...layers];
  layersToDownload.map((layer) => {
    if (
      layer instanceof SingleBandSTACLayer ||
      layer instanceof BandAlgebraSTACLayer
    ) {
      const noDataPropertyLayer = layer;
      noDataPropertyLayer.item.properties = {
        ...noDataPropertyLayer.item.properties,
        item_id: noDataPropertyLayer.item.id,
        collection_id: noDataPropertyLayer.item.collection,
      };
      // Check and stringify properties
      for (const key in noDataPropertyLayer.item.properties) {
        const val: string | object = noDataPropertyLayer.item.properties[
          key
        ] as string | object;
        noDataPropertyLayer.item.properties[key] =
          typeof val === 'object' ? JSON.stringify(val) : val;
      }
      featureCollection.features.push({
        geometry: noDataPropertyLayer.item.geometry as GeoJSON.Geometry,
        properties: noDataPropertyLayer.item.properties,
        type: 'Feature',
      });
    } else if (
      layer instanceof CircleLayer ||
      layer instanceof PolygonLayer ||
      layer instanceof LineLayer ||
      layer instanceof GeoJSONLayer ||
      layer instanceof FieldOfRegardLayer
    ) {
      const dataPropertyLayer = layer;
      (dataPropertyLayer.data as GeoJSON.Feature).properties = {
        id: dataPropertyLayer.id,
        name: dataPropertyLayer.name,
      };
      featureCollection.features.push(
        dataPropertyLayer.data as GeoJSON.Feature
      );
    }
  });
  return featureCollection;
};

export const getShpBase64 = async (geo: GeoJSON.Feature) => {
  const featCol = geoJsonFeatureToGeoJsonFeatureCollection(geo);
  const shp = await shpwrite.zip(featCol, {
    outputType: 'base64',
    compression: 'DEFLATE',
  });
  return shp;
};
