import { useState, useMemo } from 'react';
import { ALL_SATELLITES } from '_api/satellites/constants';
import { SENSOR_LIST } from '_api/sensors/constants';
import moment from 'moment';
import { useTasking } from 'datacosmos/stores/TaskingProvider';
import { SwathControl } from '_organisms/SwathControl/SwathControl';
import type { OpportunityLayer } from 'datacosmos/entities/TaskingOpportunityLayer';
import type { CommonOpportunity } from '_api/tasking/helpers';
import type { SwathLayer } from 'datacosmos/entities/SwathLayer';
import classNames from 'classnames';
import { ROLL_ANGLE_ROUNDOFF, getSwathPrice } from '_api/tasking/helpers';
import type { SwathStyleOptions } from 'datacosmos/components/Tasking/helpers';
import { useSwathControlData } from 'datacosmos/utils/hooks/useSwathControlData';
import {
  isoStringToMomentObject,
  jsDateToMomentObj,
  momentObjToJsDate,
  momentObjToUnixMs,
} from 'utils/common/dateUtils';
import { Button, Icon, Tooltip } from 'opencosmos-ui';
import type {
  TaskingRequestConstraint,
  TaskingSatellite,
} from '_api/tasking/types';
import { toaster } from 'toaster';

type OpportunityCardProps = {
  opportunity: OpportunityLayer<CommonOpportunity>;
  oppKey: number;
  pairedSwath: SwathLayer<CommonOpportunity>;
  setAllSwathLayersVisible: (value: boolean) => void;
  moreContent?: number;
  setMoreContent: (value: number | undefined) => void;
  showAllFieldsOfRegard: () => void;
  hideAllFieldsOfRegard: () => void;
  confirmedSwaths: SwathLayer<CommonOpportunity>[];
  updateLayerStyle: (value: SwathStyleOptions) => void;
  satellitesForTasking: TaskingSatellite[];
  projectIdOfTaskingRequest?: string;
};

export const OpportunityCard = ({
  opportunity,
  oppKey,
  pairedSwath,
  setAllSwathLayersVisible,
  moreContent,
  setMoreContent,
  showAllFieldsOfRegard,
  hideAllFieldsOfRegard,
  confirmedSwaths,
  updateLayerStyle,
  satellitesForTasking,
  projectIdOfTaskingRequest,
}: OpportunityCardProps) => {
  const { modifySwath, handleSwathConfirm } = useTasking();
  const { swathControlData, debouncedSetSwathData, setSwathControlData } =
    useSwathControlData(() => {
      const duration = [
        momentObjToUnixMs(isoStringToMomentObject(pairedSwath.metadata.Start)),
        momentObjToUnixMs(isoStringToMomentObject(pairedSwath.metadata.End)),
      ];

      const rotation = isNaN(Number(pairedSwath.metadata.RollAngle))
        ? 0
        : Number(
            Number(pairedSwath.metadata.RollAngle).toFixed(ROLL_ANGLE_ROUNDOFF)
          );
      return {
        duration: {
          start: momentObjToJsDate(moment(duration[0])),
          end: momentObjToJsDate(moment(duration[1])),
        },
        rotation,
        area: pairedSwath.metadata.Area ?? 0,
        coverage: pairedSwath.metadata.Benchmark.Coverage ?? 0,
        parameters: pairedSwath.metadata.Parameters,
        priority:
          pairedSwath.metadata.Priority ??
          opportunity.metadata.AvailablePriorities?.priorities?.find(
            (opp) => opp?.name === 'STANDARD'
          ),
      };
    });

  const isConfirmed = useMemo(
    () =>
      confirmedSwaths.some(
        (confirmed) => confirmed.metadata.Id === pairedSwath.metadata.Id
      ),
    [confirmedSwaths, pairedSwath]
  );

  const opportunityDuration = useMemo(() => {
    const min = Math.floor(opportunity.metadata.Duration / 60.0);
    const sec = Math.round(opportunity.metadata.Duration % 60);
    ``;
    let duration = `${sec}s`;
    if (min > 0) {
      duration = `${min}m ${duration}`;
    }
    return duration;
  }, [opportunity.metadata.Duration]);

  const [swathId, setSwathId] = useState(
    opportunity.metadata.Kind === 'internal' ? '' : pairedSwath.id
  );

  const showSwathControl = moreContent === oppKey && pairedSwath;

  const handleOpportunityCardClick = async () => {
    if (moreContent === oppKey) {
      return;
    }

    hideAllFieldsOfRegard();

    if (opportunity.metadata.Kind === 'internal') {
      setAllSwathLayersVisible(false);
    }

    updateLayerStyle({
      layerId: pairedSwath.id,
      style: 'normal',
      visible: true,
    });

    setMoreContent(oppKey);

    if (opportunity.metadata.Kind === 'internal') {
      const modifiedSwathResponse = await modifySwath(
        pairedSwath,
        swathControlData,
        projectIdOfTaskingRequest
      );
      if (modifiedSwathResponse) setSwathId(modifiedSwathResponse.id ?? '');
    }
  };

  const duration = useMemo(() => {
    return [
      momentObjToUnixMs(jsDateToMomentObj(swathControlData?.duration.start)),
      momentObjToUnixMs(jsDateToMomentObj(swathControlData?.duration.end)),
    ];
  }, [swathControlData?.duration.start, swathControlData?.duration.end]);

  const durationConstraint = useMemo(() => {
    let acquistionDuration: TaskingRequestConstraint = {
      min: Number(opportunity.metadata.Start),
      max: Number(opportunity.metadata.End),
      type: 'ACQUISITION_DURATION',
    };
    satellitesForTasking.map((sat) => {
      if (sat.mission_id === opportunity.metadata.SatelliteId) {
        sat.payloads.map((payload) => {
          if (payload.name === opportunity.metadata.SensorId) {
            payload.constraints.map((constraint) => {
              if (constraint.type === 'ACQUISITION_DURATION') {
                acquistionDuration = constraint;
              }
            });
          }
        });
      }
    });
    return acquistionDuration;
  }, [
    opportunity.metadata.SatelliteId,
    opportunity.metadata.Start,
    opportunity.metadata.End,
    opportunity.metadata.SensorId,
    satellitesForTasking,
  ]);

  const isOpportunityDurationWithinLimits = useMemo(() => {
    const oppDurationInUTC = moment.utc(
      moment.unix(duration[1] / 1000).diff(moment.unix(duration[0] / 1000))
    );
    const oppDuration =
      Number(moment(oppDurationInUTC).minutes() * 60) +
      Number(moment(oppDurationInUTC).seconds());

    return durationConstraint.max
      ? oppDuration > durationConstraint.max
      : false;
  }, [duration, durationConstraint]);

  const isPrioritySchedulingAvailable = useMemo(() => {
    return (
      opportunity.metadata.AvailablePriorities &&
      opportunity.metadata.AvailablePriorities?.priorities?.length > 0
    );
  }, [opportunity.metadata.AvailablePriorities]);

  const isConfirmDisabled = useMemo(() => {
    return (
      isOpportunityDurationWithinLimits ||
      moment(opportunity.metadata.Start).diff(moment(), 'seconds') >
        4 * 7 * 24 * 3600
    );
  }, [opportunity.metadata.Start, isOpportunityDurationWithinLimits]);

  const opportunityPrice = useMemo(() => {
    if (!opportunity.metadata.AvailablePriorities) return;
    return getSwathPrice(
      swathControlData.area,
      swathControlData?.priority?.priority_level,
      opportunity.metadata.AvailablePriorities
    );
  }, [
    opportunity.metadata.AvailablePriorities,
    swathControlData?.priority?.priority_level,
    swathControlData?.area,
  ]);

  const durationLimitTooltipText =
    !isOpportunityDurationWithinLimits && isConfirmDisabled
      ? 'Not possible to book more than 4 weeks in advance'
      : 'The swath duration is not within operation limits';

  return (
    <div
      key={oppKey}
      onClick={handleOpportunityCardClick}
      onMouseEnter={() => {
        if (showSwathControl) return;
        updateLayerStyle({
          layerId: pairedSwath.id,
          style: 'highlight',
          visible: true,
        });
      }}
      onMouseLeave={() => {
        if (showSwathControl) return;
        updateLayerStyle({
          layerId: pairedSwath.id,
          style: 'normal',
          visible: moreContent === undefined,
        });
      }}
      data-testid="opportunity"
      className={classNames(
        'w-full flex flex-col text-[12px] cursor-pointer hover:bg-item-selected',
        'border-b-2 border-b-contrast-inactive last:border-b-transparent p-1 dark:hover:bg-item-dark-selected',
        {
          'bg-item-selected dark:bg-item-dark-selected': isConfirmed,
        }
      )}
    >
      <div className="w-full flex flex-col whitespace-nowrap group">
        <div
          className={classNames(
            'flex w-full justify-between group-hover:text-accent-900 dark:group-hover:text-neutral mb-2',
            {
              'text-accent-900 dark:text-neutral': isConfirmed,
            }
          )}
        >
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center gap-2">
              <span className="font-bold">
                #{opportunity.metadata.OpportunityIndex}
              </span>
              <span className="font-bold">
                {moment
                  .utc(opportunity.metadata.Start)
                  .format('YYYY MMM DD - HH:mm:ss')}
              </span>
            </div>
            <div className="flex gap-2">
              {pairedSwath.metadata.Area !== undefined &&
                opportunity.metadata.AvailablePriorities && (
                  <Tooltip content="Price">
                    <div className="flex items-center gap-1">
                      <Icon icon="Price" />
                      <span>{'£' + opportunityPrice}</span>
                    </div>
                  </Tooltip>
                )}
              <div className="flex items-center gap-1">
                <Icon icon="Clock" />
                <span>{opportunityDuration}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-flow-col grid-cols-2 shadow-none">
          <div className="flex flex-col gap-2 mb-2">
            <Tooltip
              content={
                <span>
                  {
                    ALL_SATELLITES.find(
                      ({ satellite }) =>
                        opportunity.metadata.SatelliteId === satellite
                    )?.description
                  }
                </span>
              }
            >
              <li className="flex items-center gap-2">
                <Icon icon="Satellite" className="align-middle" />{' '}
                <span>
                  {ALL_SATELLITES.find(
                    ({ satellite }) =>
                      satellite === opportunity.metadata.SatelliteId
                  )?.uiName ?? opportunity.metadata.SatelliteId}
                </span>
              </li>
            </Tooltip>

            <Tooltip
              content={
                <span>
                  {
                    SENSOR_LIST.find(
                      (sensor) =>
                        opportunity.metadata.SatelliteId === sensor.satellite &&
                        opportunity.metadata.SensorId === sensor.sensorId
                    )?.description
                  }
                </span>
              }
            >
              <li className="flex items-center gap-2">
                <Icon icon="BandAlgebra" className="align-middle" />{' '}
                <span>{opportunity.metadata.SensorId}</span>
              </li>
            </Tooltip>
          </div>

          <div className="flex flex-wrap gap-2">
            {(opportunity.metadata.Benchmark.CloudCoverage !== undefined &&
              opportunity.metadata.Benchmark.CloudCoverage === -1) ||
            opportunity.metadata.Benchmark.CloudCoverage ===
              undefined ? null : (
              <div className="flex items-center gap-2">
                <Icon icon="Cloud" />
                <span>
                  {opportunity.metadata.Benchmark.CloudCoverage + '%'}
                </span>
              </div>
            )}

            {opportunity.metadata.Benchmark.SunGlint !== undefined ? (
              <div className="flex items-center gap-2">
                <Icon icon="Glint" />
                <span>{opportunity.metadata.Benchmark.SunGlint}</span>
              </div>
            ) : null}

            {pairedSwath.metadata.Benchmark.Coverage !== undefined && (
              <Tooltip content="AoI Coverage (%) - what percentage of the defined Area of Interest is covered by the suggested image acquisition">
                <div className="flex items-center gap-2">
                  <Icon icon="Coverage" className="dark:invert" />
                  <span>{swathControlData.coverage.toFixed(2) + '%'}</span>
                </div>
              </Tooltip>
            )}
            {pairedSwath.metadata.Oza !== undefined ? (
              <Tooltip content='OZA - Observation Zenith Angle (or Incidence Angle) is the angle between "vertical" at a point on the Earth and the line from that point to where the observation was made (i.e. the satellite)'>
                <div className="flex items-center gap-2">
                  <Icon icon="Angle" />
                  <span>
                    {swathControlData.oza?.toFixed(2) ??
                      pairedSwath.metadata.Oza.toFixed(2)}
                  </span>
                </div>
              </Tooltip>
            ) : null}

            {pairedSwath.metadata.Sza !== undefined ? (
              <Tooltip content='SZA - Sun Zenith Angle is the angle between "vertical" at a point on the Earth and the line from that point to the Sun'>
                <div className="flex items-center gap-2">
                  <Icon icon="Sun" />
                  <span>
                    {swathControlData.sza?.toFixed(2) ??
                      pairedSwath.metadata.Sza.toFixed(2)}
                  </span>
                </div>
              </Tooltip>
            ) : null}

            {pairedSwath.metadata.Area !== undefined && (
              <Tooltip content="Acquisition Size (km^2) - the area that will be covered by the suggested image acquisition">
                <div className="flex items-center gap-2">
                  <Icon icon="Area" className="dark:invert" />
                  <span>{swathControlData.area.toFixed(2) + ' km²'}</span>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>

      {showSwathControl ? (
        <SwathControl
          swath={pairedSwath.metadata}
          setSwathControlData={(val) =>
            debouncedSetSwathData(val, async () => {
              const swathResponse = await modifySwath(
                pairedSwath,
                val,
                projectIdOfTaskingRequest
              );
              if (swathResponse) {
                setSwathId(swathResponse.id ?? '');
                setSwathControlData({
                  ...val,
                  oza: swathResponse.modified_swath.midpoint.oza_deg,
                  sza: swathResponse.modified_swath.midpoint.sza_deg,
                  area: swathResponse.modified_swath.area_km2,
                  coverage: swathResponse.modified_swath.aoi_coverage ?? 0,
                });
              }
            })
          }
          opportunity={opportunity.metadata}
          swathControlData={swathControlData}
          isPrioritySchedulingAvailable={isPrioritySchedulingAvailable}
        />
      ) : null}
      {moreContent === oppKey ? (
        <div className="flex w-full gap-1 justify-center">
          <Button
            onPress={() => {
              handleSwathConfirm(swathId, swathControlData, false);
              setMoreContent(undefined);
              showAllFieldsOfRegard();
            }}
            fill
            isMinimal
            size="sm"
          >
            Cancel
          </Button>
          <Tooltip
            content={
              !isPrioritySchedulingAvailable
                ? 'Highest priority activity is already scheduled'
                : durationLimitTooltipText
            }
            placement="top"
            isDisabled={!isConfirmDisabled && isPrioritySchedulingAvailable}
            className="w-full"
          >
            <Button
              onPress={() => {
                const isStandardPriorityNotAvailable =
                  opportunity.metadata.AvailablePriorities?.priorities
                    ?.length === 1 &&
                  opportunity.metadata.AvailablePriorities?.priorities?.filter(
                    (opp) => opp?.name !== 'STANDARD'
                  );
                if (
                  isStandardPriorityNotAvailable &&
                  !swathControlData.priority
                ) {
                  toaster.show({
                    message: 'Select a priority level from the available list',
                    icon: 'warning-sign',
                    intent: 'warning',
                  });
                  return;
                }
                handleSwathConfirm(swathId, swathControlData, true);
                setMoreContent(undefined);
                showAllFieldsOfRegard();
              }}
              isDisabled={isConfirmDisabled || !isPrioritySchedulingAvailable}
              fill
              isMinimal
              size="sm"
            >
              Confirm
            </Button>
          </Tooltip>
        </div>
      ) : (
        <div className="flex items-center w-fit">
          <Button
            icon="ChevronDown"
            className="bg-transparent text-xs p-0"
            onPress={handleOpportunityCardClick}
            isMinimal
            isTransparent
          >
            View more
          </Button>
        </div>
      )}
    </div>
  );
};
