import React, { useRef, useState, type ReactElement } from "react";
import {
  OverlayArrow,
  Tooltip,
  TooltipTrigger,
  type TooltipProps,
} from "react-aria-components";
import { zIndexValues } from "../../../constants";
import { tv } from "tailwind-variants";
import { Placement } from "react-aria";

interface MyTooltipProps extends Omit<TooltipProps, "children"> {
  children: React.ReactNode;
  content: React.ReactNode;
  onPress?: () => void;
  placement?: Placement;
  className?: string;
  isDisabled?: boolean;
}

const overlayArrow = tv({
  base: "fill-neutral-700 dark:fill-neutral-100 !transform",
  variants: {
    placement: {
      top: "",
      "top end": "rotate-90",
      "top start": "-rotate-90",
      "top left": "-rotate-90",
      "top right": "rotate-90",
      "bottom end": "rotate-180",
      "bottom start": "",
      "bottom left": "rotate-180",
      "bottom right": "",
      "end bottom": "rotate-90",
      "start bottom": "-rotate-90",
      "left top": "rotate-90",
      "right top": "-rotate-90",
      "end top": "rotate-180",
      "left bottom": "rotate-180",
      "right bottom": "",
      "start top": "rotate-180",
      end: "rotate-180",
      start: "",
      bottom: "rotate-180",
      right: "rotate-90",
      left: "-rotate-90",
    } as Record<Placement, string>,
  },
  defaultVariants: {
    placement: "top",
  },
});

const tooltip = tv({
  base: "w-fit flex shadow-lg bg-neutral-700 text-item-dark-contrast dark:bg-neutral-100 dark:text-item-contrast outline-none py-2 px-4 text-sm",
});

const CustomTooltip = ({ children, ...props }: MyTooltipProps) => {
  const placement = props.placement ?? "top";
  const tooltipRef = useRef<HTMLDivElement>(null);

  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  return (
    <TooltipTrigger
      delay={0}
      closeDelay={0}
      isDisabled={props.isDisabled}
      isOpen={props.isOpen ?? isTooltipOpen}
    >
      <div
        className="flex"
        onMouseEnter={() => !props.isDisabled && setIsTooltipOpen(true)}
        onMouseLeave={() => !props.isDisabled && setIsTooltipOpen(false)}
      >
        <div ref={tooltipRef}>{children}</div>
      </div>

      <Tooltip
        {...props}
        triggerRef={tooltipRef}
        placement={placement}
        style={{
          transform: "translate3d(0, 0, 0)",
          zIndex: zIndexValues.tooltip,
        }}
        offset={props.offset ?? 4}
        className={(params) => {
          return tooltip({
            ...params,
            class: props.className,
          });
        }}
      >
        <OverlayArrow
          className={(params) => {
            let p = {
              ...params,
              placement: placement as Placement,
            };

            return overlayArrow({
              ...p,
              class: props.className,
            });
          }}
        >
          <svg width={8} height={8} viewBox="0 0 8 8">
            <path d="M0 0 L4 4 L8 0" />
          </svg>
        </OverlayArrow>
        {props.content}
      </Tooltip>
    </TooltipTrigger>
  );
};

export default CustomTooltip;
