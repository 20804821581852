import React, { useState } from 'react';
import { Formik } from 'formik';
import Input from '_molecules/Input/Input';
import type { Scenario } from '_api/scenarios/types';
import { putScenario } from '_api/scenarios/service';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useMission } from 'services/Missions';
import { Button, Dialog, ListBoxItem, Select } from 'opencosmos-ui';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';

type EditScenarioModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUserScenarios: () => void;
  detailedScenario?: Scenario;
  setCurrentScenario: React.Dispatch<
    React.SetStateAction<Scenario | undefined>
  >;
  setCurrentProject: (project: Scenario) => void;
};

export const EditScenarioModal = ({
  isOpen,
  setIsOpen,
  detailedScenario,
  setUserScenarios,
  setCurrentScenario,
  setCurrentProject,
}: EditScenarioModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { translate } = useLocalisation();
  const { organisations } = useMission();

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      buttons={[]}
      hideCancelButton
      title={translate('datacosmos.editProjectDialog.title')}
    >
      <Formik
        initialValues={{
          name: detailedScenario?.title,
          description: detailedScenario?.description,
          organisation: Number(
            detailedScenario?.summaries?.['opencosmos:organisation_id']!
          ),
        }}
        onSubmit={async (values, { setErrors, resetForm }) => {
          if (!detailedScenario) {
            return;
          }
          if (!values.name) {
            setErrors({
              name: translate('datacosmos.editProjectDialog.errors.name'),
            });
            return;
          }

          if (!values.description) {
            setErrors({
              description: translate(
                'datacosmos.editProjectDialog.errors.description'
              ),
            });
            return;
          }

          if (!values.organisation) {
            setErrors({
              organisation: translate(
                'datacosmos.editProjectDialog.errors.organisation'
              ),
            });
            return;
          }

          setIsLoading(true);
          const { data } = await putScenario({
            params: { scenarioId: detailedScenario.id },
            body: {
              name: values.name,
              description: values.description,
              organisation_id: values.organisation,
            },
          });
          setIsLoading(false);

          if (!data) return;

          setCurrentProject(data);
          setCurrentScenario(data);
          setUserScenarios();
          setIsOpen(false);
          resetForm();
        }}
      >
        {({ values, handleChange, handleSubmit, errors, setFieldValue }) => (
          <div className="flex flex-col gap-4">
            <div>
              <Input
                type="text"
                name="name"
                value={values.name}
                label={{
                  text: translate('datacosmos.editProjectDialog.name'),
                  position: 'top',
                }}
                onChange={handleChange}
              />
              <small style={{ color: 'red' }}>{errors.name?.toString()}</small>
            </div>
            <div>
              <Select
                selectedKey={values.organisation}
                onSelectionChange={(val) => {
                  void setFieldValue('organisation', val);
                }}
                placeholder="Select organisation"
                fill
                label="Organisation"
                name="organisation"
              >
                {organisations?.map((org) => (
                  <ListBoxItem id={org.id} key={org.id}>
                    {org.name}
                  </ListBoxItem>
                ))}
              </Select>
              <small style={{ color: 'red' }}>
                {errors.organisation?.toString()}
              </small>
            </div>

            <div>
              <Input
                type="textarea"
                name="description"
                value={values.description}
                label={{
                  text: translate('datacosmos.editProjectDialog.description'),
                  position: 'top',
                }}
                onChange={handleChange}
              />
              <small style={{ color: 'red' }}>
                {errors.description?.toString()}
              </small>
            </div>

            <div className="flex justify-end items-center gap-4 mt-4">
              <Button
                onPress={() => {
                  setIsOpen(false);
                }}
                size="lg"
                isMinimal
              >
                {translate('datacosmos.buttons.cancel')}{' '}
              </Button>
              <Button onPress={() => handleSubmit()} size="lg" isMinimal>
                {isLoading ? (
                  <Spinner size={20} />
                ) : (
                  translate('datacosmos.buttons.save')
                )}
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </Dialog>
  );
};
