import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { Classes, MenuItem, Checkbox, AnchorButton } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { ItemRenderer } from '@blueprintjs/select';
import { MultiSelect } from '@blueprintjs/select';
import { isNil, xor } from 'lodash';
import { TimePicker, TimePrecision } from '@blueprintjs/datetime';
import { Button, DateRangePicker, Icon, Tooltip } from 'opencosmos-ui';
import Accordion from 'opencosmos-ui/src/core/Accordion/Accordion';
import moment from 'moment';
import { useMap } from 'datacosmos/stores/MapProvider';
import s from './filters.module.scss';
import { IMAGE_BAND } from 'datacosmos/types/stac-types';
import UploadRegion from '../UploadRegion';
import DrawAreaOfInterest from '../DrawAreaOfInterest';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useFilters } from '../../stores/FiltersProvider';
import type { CondataSatellite, Satellite } from '_api/satellites/types';
import {
  SATELLITES_FOR_PRODUCTION,
  CONDATA_SATELLITES,
} from '_api/satellites/constants';
import RangeSlider from '_molecules/RangeSlider/RangeSlider';
import { clientTranslate, useLocalisation } from 'utils/hooks/useLocalisation';
import SearchByPoint from '../SearchByPoint';
import MapCoveringModal from '_organisms/MapCoveringModal/MapCoveringModal';
import SearchBySpecificRegion from '../SearchBySpecificRegion';
import {
  HOURS_OF_THE_DAY_FILTER_ENABLE,
  SENSOR_TYPE_FILTER_ENABLE,
  PLATFORM_TYPE_FILTER_ENABLE,
  DATACOSMOS_APPLICATION_SUITE,
} from 'env';
import { useTheme } from 'datacosmos/stores/ThemeProvider';
import Fuse from 'fuse.js';
import { useHistory } from 'react-router';
import bboxPolygon from '@turf/bbox-polygon';
import { parseDate } from '@internationalized/date';
import type { IconName } from 'opencosmos-ui/src/icons/Icon';
import FilterTitle from './FilterTitle';
import { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';

const MODAL_MIN_WIDTH = 445;

type MultiselectOption = {
  id: string;
  label: string;
};

const toTranslatedOptions = (list: string[], keyPrefix: string) => {
  return list.map((option) => ({
    id: option,
    label: clientTranslate(
      `datacosmos.filters.${keyPrefix}.options.${option}` as unknown as TemplateStringsArray
    ),
  }));
};

const RESOLUTION = toTranslatedOptions(['limited', 'full'], 'resolution');

const ASSET_TYPE = ['Raster', 'Vector'].map((x) => ({ id: x, label: x }));

const SOURCE = toTranslatedOptions(
  ['Satellite Imagery', 'Algorithm'],
  'source'
);

const SEASONS = toTranslatedOptions(
  ['Summer', 'Winter', 'Autumn', 'Spring', 'Rainy', 'Dry'],
  'seasons'
);

const CONDATA_PROCESSING_LEVELS = [
  'L0',
  'L1A',
  'L1B',
  'L1C',
  'L1D',
  'L1R',
  'L1TP',
  'L2',
  'L2A',
  'L2SP',
  'L3',
  'L4',
  'ORTHO',
  'PRIMARY',
  'SENSOR',
];
const PRODUCTION_PROCESSING_LEVELS = ['L0', 'L1A', 'L1B', 'L1C', 'L1D', 'L2A'];
const PROCESSING_LEVEL =
  DATACOSMOS_APPLICATION_SUITE === 'conida'
    ? CONDATA_PROCESSING_LEVELS
    : PRODUCTION_PROCESSING_LEVELS;

const PRODUCT_TYPE = toTranslatedOptions(
  ['Satellite', 'Vector', 'Insight'],
  'productType'
);

const SENSOR_TYPE = toTranslatedOptions(
  ['Multispectral', 'Hyperspectral', 'Radar'],
  'sensorType'
);
const PLATFORM_TYPE = toTranslatedOptions(['Satellite', 'UAV'], 'platformType');

const COLLECTION_TYPE = toTranslatedOptions(
  ['Nominal', 'QA'],
  'collectionType'
);

const QA_STATUS = toTranslatedOptions(['Rejected'], 'qaStatus');

const IMAGE_BANDS = toTranslatedOptions(Object.keys(IMAGE_BAND), 'imageBand');

const SatelliteMultiSelect = MultiSelect.ofType<Satellite | CondataSatellite>();

interface Props {
  isViewingSingleItem: boolean;
}

const Filters = ({ isViewingSingleItem }: Props) => {
  const SATELLITES_FOR_FILTERS =
    DATACOSMOS_APPLICATION_SUITE === 'conida'
      ? CONDATA_SATELLITES
      : SATELLITES_FOR_PRODUCTION;

  const {
    satelliteFilters,
    setSatelliteFilters,
    dateRangeFilter,
    setDateRangeFilter,
    hoursRangeFilter,
    setHoursRangeFilter,
    areFiltersApplied,
    clearAllFilters,
    SZAFilter,
    setSZAFilter,
    OZAFilter,
    setOZAFilter,
    GSDFilter,
    setGSDFilter,
    cloudCoverageFilter,
    setCloudCoverageFilter,
    imageBandFilter,
    processingLevelFilter,
    productTypeFilter,
    setImageBandFilter,
    setProcessingLevelFilter,
    setProductTypeFilter,
    setAssetTypeFilter,
    assetTypeFilter,
    sourceFilter,
    setSourceFilter,
    seasonsFilter,
    setSeasonsFilter,
    sunGlintFilter,
    setSunGlintFilter,
    setAreaOfInterest,
    getNumberOfActiveFilters,
    areaOfInterest,
    resolutionFilter,
    setResolutionFilter,
    sensorFilter,
    setSensorFilter,
    platformTypeFilter,
    setPlatformTypeFilter,
    collectionTypeFilter,
    setCollectionTypeFilter,
    qaStatusFilter,
    setQaStatusFilter,
  } = useFilters();

  const isSzaFilterActive =
    SZAFilter.min !== undefined || SZAFilter.max !== undefined;
  const isOzaFilterActive =
    OZAFilter.min !== undefined || OZAFilter.max !== undefined;
  const isCloudCoverageFilterActive = cloudCoverageFilter !== undefined;

  const isSunGlintFilterActive =
    sunGlintFilter.max !== undefined || sunGlintFilter.min !== undefined;

  const { removeLayersBySourceType, layers } = useMapLayers();

  const { getMapBounds, mapContainerRef, currentCenter, disableEditing } =
    useMap();

  const [isCheckedCurrentView, setIsCheckedCurrentView] = useState(false);
  const [openSections, setOpenSections] = useState<string[]>([]);

  const { translate } = useLocalisation();
  const { isDarkmode } = useTheme();
  const history = useHistory();

  useEffect(() => {
    const bboxValue = getMapBounds();
    if (bboxValue && isCheckedCurrentView) {
      const aoi = [bboxPolygon(bboxValue).geometry];
      setAreaOfInterest(aoi);
    }
  }, [getMapBounds, isCheckedCurrentView, setAreaOfInterest, currentCenter]);

  useEffect(() => {
    if (!areaOfInterest?.length) {
      setIsCheckedCurrentView(false);
      removeLayersBySourceType(LayerSourceType.AREA_OF_INTEREST);
    }
  }, [areaOfInterest, removeLayersBySourceType]);

  const renderItemGenerator = (selectedItems: string[]) => {
    const renderer: ItemRenderer<MultiselectOption | undefined> = (
      item,
      { handleClick, modifiers }
    ) => {
      if (!item) return null;
      const selected = selectedItems?.includes(item?.id);

      return (
        <MenuItem
          key={item.id}
          text={item.label}
          onClick={handleClick}
          icon={selected ? IconNames.TICK : IconNames.BLANK}
          active={modifiers.active}
        />
      );
    };
    return renderer;
  };

  const renderSatellite: ItemRenderer<Satellite | CondataSatellite> = (
    item,
    { handleClick, modifiers }
  ) => {
    const selected = satelliteFilters.includes(item.COSPAR);

    return (
      <MenuItem
        key={item.COSPAR}
        text={item.uiName}
        onClick={handleClick}
        icon={selected ? IconNames.TICK : IconNames.BLANK}
        active={modifiers.active}
      />
    );
  };

  const processingLevelRenderItem: ItemRenderer<string> = (
    item,
    { handleClick, modifiers }
  ) => {
    const selected = processingLevelFilter.includes(item);

    return (
      <MenuItem
        key={item}
        text={item}
        onClick={handleClick}
        icon={selected ? IconNames.TICK : IconNames.BLANK}
        active={modifiers.active}
      />
    );
  };

  const handleSatelliteToggle = (item: Satellite | CondataSatellite) =>
    setSatelliteFilters((previousFilters: string[]) =>
      xor(previousFilters, [item.COSPAR])
    );

  const handleProcessingLevelToggle = (item: string | undefined) => {
    if (item === undefined) {
      return;
    }
    setProcessingLevelFilter((previousFilters: string[]) =>
      xor(previousFilters, [item])
    );
  };

  const handleImageBandToggle = (item: string | undefined) => {
    if (item === undefined) {
      return;
    }
    setImageBandFilter((previousFilters: string[]) =>
      xor(previousFilters, [item])
    );
  };

  const handleProductTypeToggle = (item: string | undefined) => {
    if (item === undefined) {
      return;
    }
    setProductTypeFilter((previousFilters: string[]) =>
      xor(previousFilters, [item])
    );
  };

  const handleOnHoursRangeChange = (value: Date, type: string) => {
    type === 'lte' &&
      setHoursRangeFilter({
        ...hoursRangeFilter,
        lte: value,
      });
    type === 'gte' &&
      setHoursRangeFilter({
        ...hoursRangeFilter,
        gte: value,
      });
  };

  const handleAssetTypeToggle = (item: string | undefined) => {
    if (item === undefined) {
      return;
    }
    setAssetTypeFilter((previousFilters: string[]) =>
      xor(previousFilters, [item])
    );
  };

  const handleSourceToggle = (item: string | undefined) => {
    if (item === undefined) {
      return;
    }
    setSourceFilter((previousFilters: string[]) =>
      xor(previousFilters, [item])
    );
  };

  const handleSeasonsToggle = (item: string | undefined) => {
    if (item === undefined) {
      return;
    }
    setSeasonsFilter((previousFilters: string[]) =>
      xor(previousFilters, [item])
    );
  };

  const handleCollectionTypeToggle = (item: string | undefined) => {
    if (item === undefined) {
      return;
    }
    setCollectionTypeFilter((previousFilters: string[]) =>
      xor(previousFilters, [item?.toLowerCase()])
    );
  };

  const handleQaStatusToggle = (item: string | undefined) => {
    if (item === undefined) {
      return;
    }
    setQaStatusFilter((previousFilters: string[]) =>
      xor(previousFilters, [item?.toLowerCase()])
    );
  };

  const handleResolutionToggle = (item: string | undefined) => {
    if (item === undefined) {
      return;
    }
    setResolutionFilter((previousFilters: string[]) =>
      xor(previousFilters, [item])
    );
  };

  const handleSensorTypeToggle = (item: string | undefined) => {
    if (item === undefined) {
      return;
    }
    setSensorFilter((previousFilters: string[]) =>
      xor(previousFilters, [item])
    );
  };
  const handlePlatformTypeToggle = (item: string | undefined) => {
    if (item === undefined) {
      return;
    }
    setPlatformTypeFilter((previousFilters: string[]) =>
      xor(previousFilters, [item])
    );
  };

  const handleSZAChange = (values: number[]) => {
    setSZAFilter({ ...SZAFilter, min: values[0], max: values[1] });
  };

  const handleOZAChange = (values: number[]) => {
    setOZAFilter({ ...OZAFilter, min: values[0], max: values[1] });
  };

  const options: Fuse.FuseOptions<Satellite | CondataSatellite> = {
    shouldSort: true,
    tokenize: true,
    matchAllTokens: true,
    keys: ['uiName'],
  };

  const filterSatellites = (
    query: string,
    items: Satellite[] | CondataSatellite[]
  ) => {
    if (query) {
      const fuse = new Fuse(items, options);
      const searchResults = fuse.search(query) as Satellite[];
      return searchResults;
    }
    return SATELLITES_FOR_FILTERS;
  };

  const handleGSDChange = (
    event: ChangeEvent<HTMLInputElement>,
    range: string
  ) => {
    let { value } = event.target;
    const { min, max } = event.target;
    value === ''
      ? (value = '')
      : (value = String(
          Math.max(Number(min), Math.min(Number(max), Number(value)))
        ));
    range === 'min' && setGSDFilter({ ...GSDFilter, min: Number(value) });
    range === 'max' && setGSDFilter({ ...GSDFilter, max: Number(value) });
  };
  const handleSunGlintChange = (values: number[]) => {
    setSunGlintFilter({ ...sunGlintFilter, min: values[0], max: values[1] });
  };

  const noOfActiveFilters = getNumberOfActiveFilters();

  const getFilterIconAndText = (isExpanded: boolean) => {
    const icon: IconName = isExpanded ? 'ChevronLeftDouble' : 'Filter';

    const btnText = isExpanded
      ? translate('datacosmos.buttons.closeFilters')
      : translate('datacosmos.buttons.filters');
    return {
      icon: icon,
      btnText: btnText,
    };
  };

  const labelRendererGenerator = (counter: number, sectionId: string) => {
    const labelRenderer = (label: string) => (
      <div
        className="flex justify-between w-full"
        data-testid={`filters-${sectionId}-section`}
      >
        <span>{label}</span>
        {counter !== 0 && (
          <span
            style={{
              borderRadius: '50%',
              height: '19px',
              border: '1px solid rgba(115, 134, 148, 0.3)',
              width: '19px',
              textAlign: 'center',
              fontSize: '13px',
              cursor: 'pointer',
            }}
            className="color-item"
          >
            {counter}
          </span>
        )}
      </div>
    );
    return labelRenderer;
  };

  const { hasPermission: canViewInternalFilters } = useCheckPermissions({
    permissions: {
      type: 'global',
      actionScope: 'data:catalog:internal_filters',
    },
  });

  return (
    <div className="z-20 w-full">
      <MapCoveringModal
        mapContainerWidthPx={
          Math.min(mapContainerRef.current?.clientWidth!, MODAL_MIN_WIDTH) ?? 0
        }
        getModalTrigger={(triggerRef, triggerProps, toggleModal) => (
          <div className="relative w-full">
            <Button
              iconPlacement="left"
              className="w-full justify-start"
              size="base"
              icon={
                isViewingSingleItem
                  ? 'chevron-left'
                  : getFilterIconAndText(
                      triggerProps['aria-expanded'] as boolean
                    ).icon
              }
              data-testid="datacosmos-search-results-filter-toggle-button"
              {...triggerProps}
              onPress={() => {
                if (!isViewingSingleItem) {
                  toggleModal();
                  return;
                }
                history.replace({
                  pathname: history.location.pathname,
                  search: undefined,
                });
              }}
              ref={triggerRef}
              fill={true}
            >
              <span className="text-center w-full text-sm">
                {isViewingSingleItem
                  ? translate('datacosmos.buttons.backToSearch')
                  : getFilterIconAndText(
                      triggerProps['aria-expanded'] as boolean
                    ).btnText}
              </span>
            </Button>

            {noOfActiveFilters.total !== 0 && (
              <span
                style={{
                  position: 'absolute',
                  borderRadius: '50%',
                  height: '19px',
                  border: '1px solid rgba(115, 134, 148, 0.3)',
                  width: '19px',
                  textAlign: 'center',
                  top: -11,
                  right: -10,
                  fontSize: '13px',
                  cursor: 'pointer',
                }}
                className="color-item"
                onClick={toggleModal}
              >
                {noOfActiveFilters.total}
              </span>
            )}
          </div>
        )}
      >
        {(toggleModal, openModal) => (
          <div className="w-full flex flex-col gap-4">
            <div
              className={
                'dark:bg-surface-dark dark:text-item-dark-contrast bg-surface text-item-contrast gap-2 !shadow-none md:flex md:flex-col'
              }
            >
              <Accordion
                labelText={translate('datacosmos.filters.sections.product')}
                labelRenderer={labelRendererGenerator(
                  noOfActiveFilters.product,
                  'product'
                )}
                labelClassName="w-full"
                startExpanded
                isOpen
                hideIcon
              >
                <div className="dark:text-item-dark-contrast flex flex-col gap-1 p-2">
                  {PLATFORM_TYPE_FILTER_ENABLE && (
                    <div>
                      <FilterTitle
                        title={translate(
                          'datacosmos.filters.platformType.title'
                        )}
                        tooltipContent={translate(
                          'datacosmos.filters.detailedTooltips.platformType.description'
                        )}
                        icon="Satellite"
                      />

                      <MultiSelect
                        placeholder={translate(
                          'datacosmos.filters.placeholder'
                        )}
                        className={[Classes.FILL].join(' ')}
                        items={PLATFORM_TYPE}
                        tagRenderer={(tag) => tag?.label}
                        itemRenderer={renderItemGenerator(platformTypeFilter)}
                        onItemSelect={(item) =>
                          handlePlatformTypeToggle(item?.id)
                        }
                        selectedItems={PLATFORM_TYPE.filter((x) =>
                          platformTypeFilter.includes(x.id)
                        )}
                        fill={true}
                        tagInputProps={{
                          onRemove: (_, i) =>
                            handlePlatformTypeToggle(platformTypeFilter.at(i)),
                        }}
                      />
                    </div>
                  )}
                  <div>
                    <FilterTitle
                      title={translate('datacosmos.filters.satellite')}
                      icon="satellite"
                    />

                    <SatelliteMultiSelect
                      className={[Classes.FILL].join(' ')}
                      items={SATELLITES_FOR_FILTERS}
                      tagRenderer={(tag) => tag.uiName}
                      itemRenderer={renderSatellite}
                      onItemSelect={handleSatelliteToggle}
                      itemListPredicate={filterSatellites}
                      resetOnSelect
                      selectedItems={SATELLITES_FOR_FILTERS.filter((sfp) =>
                        satelliteFilters.some((sf) => sf === sfp.COSPAR)
                      )}
                      fill={true}
                      tagInputProps={{
                        onRemove: (sat) => {
                          const toRemove = SATELLITES_FOR_FILTERS.find(
                            (sfp) => sfp.uiName === sat
                          );
                          if (toRemove !== undefined) {
                            handleSatelliteToggle(toRemove);
                          }
                        },
                      }}
                      placeholder={translate('datacosmos.filters.placeholder')}
                    />
                  </div>
                  {/* Product Type */}
                  <div>
                    <FilterTitle
                      title={translate('datacosmos.filters.productType.title')}
                      icon="tag"
                    />

                    <MultiSelect
                      placeholder={translate('datacosmos.filters.placeholder')}
                      className={[Classes.FILL].join(' ')}
                      items={PRODUCT_TYPE}
                      tagRenderer={(tag) => tag?.label}
                      itemRenderer={renderItemGenerator(productTypeFilter)}
                      onItemSelect={(item) => handleProductTypeToggle(item?.id)}
                      selectedItems={PRODUCT_TYPE.filter((x) =>
                        productTypeFilter.includes(x.id)
                      )}
                      fill={true}
                      tagInputProps={{
                        onRemove: (_, i) =>
                          handleProductTypeToggle(productTypeFilter.at(i)),
                      }}
                    />
                  </div>
                </div>
              </Accordion>
              <Accordion
                labelText={translate('datacosmos.filters.sections.location')}
                labelRenderer={labelRendererGenerator(
                  noOfActiveFilters.location,
                  'location'
                )}
                labelClassName="w-full"
                isOpen={openSections.includes('location')}
                onToggle={(isOpen: boolean) =>
                  setOpenSections(isOpen ? ['location'] : [])
                }
              >
                <div className="dark:text-item-dark-contrast flex flex-col gap-1 p-2">
                  <FilterTitle
                    title={translate('datacosmos.filters.geometry')}
                    icon={'shapes'}
                  />

                  <div className="flex items-center gap-1">
                    <Checkbox
                      large={true}
                      checked={isCheckedCurrentView}
                      label={translate('datacosmos.filters.currentViewArea')}
                      onChange={() => {
                        if (isCheckedCurrentView) {
                          setAreaOfInterest(undefined);
                          return;
                        }
                        setIsCheckedCurrentView(!isCheckedCurrentView);
                      }}
                    />
                    <Tooltip
                      content={translate(
                        'datacosmos.filters.detailedTooltips.currentViewArea.description'
                      )}
                    >
                      <Icon icon="Info" />
                    </Tooltip>
                  </div>

                  <UploadRegion
                    aoiSourceType={LayerSourceType.AREA_OF_INTEREST}
                    areaOfInterest={areaOfInterest}
                    setAreaOfInterest={setAreaOfInterest}
                    buttonTitle={translate('datacosmos.filters.uploadAoi')}
                    onPressExtraAction={toggleModal}
                    multipleAois={true}
                    disableUploadValidation={true}
                  />
                  <DrawAreaOfInterest
                    aoiSourceType={LayerSourceType.AREA_OF_INTEREST}
                    setAreasOfInterest={setAreaOfInterest}
                    onPressExtraAction={toggleModal}
                    onDrawFinish={openModal}
                  />

                  <SearchByPoint setAreaOfInterest={setAreaOfInterest} />
                  <SearchBySpecificRegion
                    setAreaOfInterest={setAreaOfInterest}
                  />

                  <Button
                    icon="Trash"
                    iconPlacement="left"
                    intent="warning"
                    className="w-full justify-start"
                    isMinimal
                    size="base"
                    onPress={() => {
                      if (areaOfInterest) {
                        const aoiLayer = layers.find(
                          (layer) =>
                            layer instanceof PolygonLayer &&
                            layer.sourceType ===
                              LayerSourceType.AREA_OF_INTEREST
                        ) as PolygonLayer;

                        disableEditing(aoiLayer.getLeafletLayerMetadata(), {
                          toggleEditingRef: true,
                        });
                        setAreaOfInterest(undefined);
                      }
                    }}
                  >
                    <span className="text-center w-full text-sm">
                      {translate('datacosmos.filters.clearAoi')}
                    </span>
                  </Button>
                </div>
              </Accordion>

              <Accordion
                labelText={translate('datacosmos.filters.sections.period')}
                labelRenderer={labelRendererGenerator(
                  noOfActiveFilters.period,
                  'period'
                )}
                labelClassName="w-full"
                isOpen={openSections.includes('period')}
                onToggle={(isOpen: boolean) =>
                  setOpenSections(isOpen ? ['period'] : [])
                }
              >
                <div className="p-2 flex flex-col gap-2">
                  <div className="filter-datepicker">
                    <FilterTitle
                      title={translate('datacosmos.filters.dates')}
                      icon={'Calendar'}
                      tooltipContent={translate(
                        'datacosmos.filters.detailedTooltips.dates.description'
                      )}
                    />

                    <div className={s.dateRangeContainer}>
                      <DateRangePicker
                        granularity="day"
                        onChange={(range) => {
                          setDateRangeFilter([
                            new Date(range.start.toString()),
                            new Date(range.end.toString()),
                          ]);
                        }}
                        value={
                          !isNil(dateRangeFilter[0]) &&
                          !isNil(dateRangeFilter[1])
                            ? {
                                start: parseDate(
                                  moment
                                    .utc(dateRangeFilter[0])
                                    .format('YYYY-MM-DD')
                                ),
                                end: parseDate(
                                  moment
                                    .utc(dateRangeFilter[1])
                                    .format('YYYY-MM-DD')
                                ),
                              }
                            : null
                        }
                        minValue={parseDate(
                          moment.utc().subtract(10, 'year').format('YYYY-MM-DD')
                        )}
                        maxValue={parseDate(moment.utc().format('YYYY-MM-DD'))}
                      />
                    </div>
                  </div>
                  <div>
                    <FilterTitle
                      title={translate('datacosmos.filters.seasons.title')}
                      icon={'snowflake'}
                    />

                    <MultiSelect
                      placeholder={translate('datacosmos.filters.placeholder')}
                      className={[Classes.FILL].join(' ')}
                      items={SEASONS}
                      tagRenderer={(tag) => tag?.label}
                      itemRenderer={renderItemGenerator(seasonsFilter)}
                      onItemSelect={(item) => handleSeasonsToggle(item?.id)}
                      selectedItems={SEASONS.filter((x) =>
                        seasonsFilter.includes(x.id)
                      )}
                      fill={true}
                      tagInputProps={{
                        onRemove: (_, i) =>
                          handleSeasonsToggle(seasonsFilter.at(i)),
                      }}
                    />
                  </div>
                  {HOURS_OF_THE_DAY_FILTER_ENABLE && (
                    <div className="hours-of-the-day-picker">
                      <FilterTitle
                        title={translate('datacosmos.filters.hours')}
                        icon={'time'}
                        tooltipContent={translate(
                          'datacosmos.filters.detailedTooltips.hoursOfDay.description'
                        )}
                      />

                      <div className="flex justify-between w-full gap-2">
                        <div className="flex items-center gap-2">
                          <h6 className="m-0">
                            {translate('datacosmos.filters.fromHours')}
                          </h6>
                          <TimePicker
                            autoFocus={false}
                            precision={TimePrecision.MINUTE}
                            useAmPm={false}
                            showArrowButtons={false}
                            value={hoursRangeFilter.gte}
                            onChange={(value: Date) =>
                              handleOnHoursRangeChange(value, 'gte')
                            }
                            className="hoursOfTheDayInput"
                          />
                        </div>

                        <div className="flex items-center gap-2">
                          <h6 className="m-0">
                            {translate('datacosmos.filters.toHours')}
                          </h6>
                          <TimePicker
                            autoFocus={false}
                            precision={TimePrecision.MINUTE}
                            useAmPm={false}
                            showArrowButtons={false}
                            value={
                              hoursRangeFilter.lte
                                ? hoursRangeFilter.lte
                                : new Date(new Date().setHours(23, 59, 0, 0))
                            }
                            onChange={(value: Date) =>
                              handleOnHoursRangeChange(value, 'lte')
                            }
                            className="hoursOfTheDayInput"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Accordion>

              <Accordion
                labelText={translate('datacosmos.filters.sections.advanced')}
                labelRenderer={labelRendererGenerator(
                  noOfActiveFilters.advanced,
                  'advanced'
                )}
                labelClassName="w-full"
                isOpen={openSections.includes('advanced')}
                onToggle={(isOpen: boolean) =>
                  setOpenSections(isOpen ? ['advanced'] : [])
                }
              >
                <div className="p-2 flex flex-col">
                  <Accordion
                    labelText={translate(
                      'datacosmos.filters.sections.imageProcessingLevel'
                    )}
                    labelRenderer={labelRendererGenerator(
                      noOfActiveFilters.imageProvessingLevel,
                      'image-processing-level'
                    )}
                    labelClassName="w-full"
                    isOpen={openSections.includes('imageProcessingLevel')}
                    onToggle={(isOpen: boolean) =>
                      setOpenSections(
                        isOpen
                          ? ['advanced', 'imageProcessingLevel']
                          : ['advanced']
                      )
                    }
                  >
                    <div className="p-2 flex flex-col gap-2">
                      <FilterTitle
                        title={translate('datacosmos.filters.processingLevel')}
                        icon={'Layers'}
                        tooltipContent={translate(
                          'datacosmos.filters.detailedTooltips.processingLevel.description'
                        )}
                      />

                      <MultiSelect
                        placeholder={translate(
                          'datacosmos.filters.placeholder'
                        )}
                        className={[Classes.FILL].join(' ')}
                        items={PROCESSING_LEVEL}
                        tagRenderer={(tag) => tag}
                        itemRenderer={processingLevelRenderItem}
                        onItemSelect={handleProcessingLevelToggle}
                        selectedItems={processingLevelFilter}
                        fill={true}
                        tagInputProps={{
                          onRemove: (n, _) =>
                            handleProcessingLevelToggle(n?.toString()),
                        }}
                      />
                    </div>
                  </Accordion>
                  <Accordion
                    labelText={translate(
                      'datacosmos.filters.sections.sensorSpecification'
                    )}
                    labelRenderer={labelRendererGenerator(
                      noOfActiveFilters.sensorSpecification,
                      'sensor-specification'
                    )}
                    labelClassName="w-full"
                    isOpen={openSections.includes('sensorSpecification')}
                    onToggle={(isOpen: boolean) =>
                      setOpenSections(
                        isOpen
                          ? ['advanced', 'sensorSpecification']
                          : ['advanced']
                      )
                    }
                  >
                    <div className="p-2 flex flex-col gap-2">
                      {SENSOR_TYPE_FILTER_ENABLE && (
                        <div>
                          <FilterTitle
                            title={translate(
                              'datacosmos.filters.sensorType.title'
                            )}
                            icon={'antenna'}
                            tooltipContent={translate(
                              'datacosmos.filters.detailedTooltips.sensorType.description'
                            )}
                          />

                          <MultiSelect
                            placeholder={translate(
                              'datacosmos.filters.placeholder'
                            )}
                            className={[Classes.FILL].join(' ')}
                            items={SENSOR_TYPE}
                            tagRenderer={(tag) => tag?.label}
                            itemRenderer={renderItemGenerator(sensorFilter)}
                            onItemSelect={(item) =>
                              handleSensorTypeToggle(item?.id)
                            }
                            selectedItems={SENSOR_TYPE.filter((x) =>
                              sensorFilter.includes(x.id)
                            )}
                            fill={true}
                            tagInputProps={{
                              onRemove: (_, i) =>
                                handleSensorTypeToggle(sensorFilter.at(i)),
                            }}
                          />
                        </div>
                      )}
                      {/* Image Band */}
                      <div>
                        <FilterTitle
                          title={translate(
                            'datacosmos.filters.imageBand.title'
                          )}
                          icon={'helper-management'}
                          tooltipContent={translate(
                            'datacosmos.filters.detailedTooltips.imageBand.description'
                          )}
                        />

                        <MultiSelect
                          placeholder={translate(
                            'datacosmos.filters.placeholder'
                          )}
                          className={[Classes.FILL].join(' ')}
                          items={IMAGE_BANDS}
                          tagRenderer={(tag) => tag?.label}
                          itemRenderer={renderItemGenerator(imageBandFilter)}
                          onItemSelect={(item) =>
                            handleImageBandToggle(item?.id)
                          }
                          selectedItems={IMAGE_BANDS.filter((x) =>
                            imageBandFilter.includes(x.id)
                          )}
                          fill={true}
                          tagInputProps={{
                            onRemove: (_, i) =>
                              handleImageBandToggle(imageBandFilter.at(i)),
                          }}
                        />
                      </div>
                      {/* Min Max GSD */}
                      <div className="filter-datepicker">
                        <FilterTitle
                          title={translate('datacosmos.filters.gsd')}
                          icon={'zoom-to-fit'}
                          tooltipContent={translate(
                            'datacosmos.filters.detailedTooltips.gsd.description'
                          )}
                        />

                        <div className={Classes.CONTROL_GROUP}>
                          <div
                            className={
                              Classes.INPUT_GROUP + '  ' + s.gsdInputFields
                            }
                          >
                            <input
                              type="number"
                              className={Classes.INPUT}
                              placeholder={translate('datacosmos.filters.min')}
                              value={GSDFilter.min}
                              min={0}
                              max={100}
                              onChange={(e) => handleGSDChange(e, 'min')}
                            />
                            <br />
                          </div>
                          <div
                            className={
                              Classes.INPUT_GROUP + '  ' + s.gsdInputFields
                            }
                          >
                            <input
                              type="number"
                              placeholder={translate('datacosmos.filters.min')}
                              className={Classes.INPUT}
                              value={GSDFilter.max}
                              min={0}
                              max={100}
                              onChange={(e) => handleGSDChange(e, 'max')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <Accordion
                    labelText={translate(
                      'datacosmos.filters.sections.observationConditions'
                    )}
                    labelRenderer={labelRendererGenerator(
                      noOfActiveFilters.observationConditions,
                      'observation-conditions'
                    )}
                    labelClassName="w-full"
                    isOpen={openSections.includes('observationConditions')}
                    onToggle={(isOpen: boolean) =>
                      setOpenSections(
                        isOpen
                          ? ['advanced', 'observationConditions']
                          : ['advanced']
                      )
                    }
                  >
                    <div className="p-2 flex flex-col gap-2">
                      <div className="flex-col w-full">
                        <div
                          className={`flex items-center w-full cursor-pointer hover:bg-item-selected dark:hover:bg-neutral-800`}
                          onClick={() => {
                            if (isSzaFilterActive) {
                              setSZAFilter({
                                min: undefined,
                                max: undefined,
                              });
                            } else {
                              setSZAFilter({
                                min: 0,
                                max: 0,
                              });
                            }
                          }}
                        >
                          <div className="flex items-center w-full gap-4">
                            <FilterTitle
                              title={translate(
                                'datacosmos.filters.detailedTooltips.sza.content'
                              )}
                              icon={'flash'}
                              tooltipContent={translate(
                                'datacosmos.filters.detailedTooltips.sza.description'
                              )}
                              className="text-sm font-normal"
                            />
                          </div>
                          <AnchorButton
                            icon={
                              isSzaFilterActive ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke={isDarkmode ? 'white' : 'currentColor'}
                                  strokeWidth={2}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  />
                                </svg>
                              ) : (
                                IconNames.ADD
                              )
                            }
                            minimal
                            small
                            data-testid="datacosmos-item-search-filters-sza-add-remove"
                          />
                        </div>
                        {isSzaFilterActive && (
                          <RangeSlider
                            minValue={0}
                            maxValue={180}
                            numberOfHandles={2}
                            onChange={(values) => {
                              handleSZAChange(values);
                            }}
                            showValuesAboveHandles
                            step={0.1}
                            value={[SZAFilter.min ?? 0, SZAFilter.max ?? 180]}
                            data-testid="datacosmos-item-search-filters-sza-slider"
                          />
                        )}
                      </div>

                      <div className="flex-col w-full">
                        <div
                          className={`flex items-center w-full cursor-pointer hover:bg-item-selected dark:hover:bg-neutral-800`}
                          onClick={() => {
                            if (isOzaFilterActive) {
                              setOZAFilter({
                                min: undefined,
                                max: undefined,
                              });
                            } else {
                              setOZAFilter({
                                min: 0,
                                max: 0,
                              });
                            }
                          }}
                        >
                          <div className="flex items-center w-full gap-4">
                            <FilterTitle
                              title={translate(
                                'datacosmos.filters.detailedTooltips.oza.content'
                              )}
                              icon={'flash'}
                              tooltipContent={translate(
                                'datacosmos.filters.detailedTooltips.oza.description'
                              )}
                              className="text-sm font-normal"
                            />
                          </div>
                          <AnchorButton
                            icon={
                              isOzaFilterActive ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke={isDarkmode ? 'white' : 'currentColor'}
                                  strokeWidth={2}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  />
                                </svg>
                              ) : (
                                IconNames.ADD
                              )
                            }
                            minimal
                            small
                            data-testid="datacosmos-item-search-filters-oza-add-remove"
                          />
                        </div>
                        {isOzaFilterActive && (
                          <RangeSlider
                            minValue={0}
                            maxValue={180}
                            numberOfHandles={2}
                            onChange={(values) => {
                              handleOZAChange(values);
                            }}
                            showValuesAboveHandles
                            step={0.1}
                            value={[OZAFilter.min ?? 0, OZAFilter.max ?? 180]}
                          />
                        )}
                      </div>

                      <div className="flex-col w-full">
                        <div
                          className={`flex items-center w-full cursor-pointer hover:bg-item-selected dark:hover:bg-neutral-800`}
                          onClick={() => {
                            if (isCloudCoverageFilterActive) {
                              setCloudCoverageFilter(undefined);
                            } else {
                              setCloudCoverageFilter(0);
                            }
                          }}
                        >
                          <div className="flex items-center w-full gap-4">
                            <FilterTitle
                              title={translate(
                                'datacosmos.filters.cloudCoverage'
                              )}
                              icon={'cloud'}
                              tooltipContent={translate(
                                'datacosmos.filters.detailedTooltips.cloudCoverage.description'
                              )}
                              className="text-sm font-normal"
                            />
                          </div>
                          <AnchorButton
                            icon={
                              isCloudCoverageFilterActive ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke={isDarkmode ? 'white' : 'currentColor'}
                                  strokeWidth={2}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  />
                                </svg>
                              ) : (
                                IconNames.ADD
                              )
                            }
                            minimal
                            small
                            data-testid="datacosmos-item-search-filters-cloud-cover-add-remove"
                          />
                        </div>
                        {isCloudCoverageFilterActive && (
                          <RangeSlider
                            minValue={0}
                            maxValue={100}
                            numberOfHandles={1}
                            onChange={(values) => {
                              setCloudCoverageFilter(values[0]);
                            }}
                            showValuesAboveHandles
                            step={1}
                            value={[cloudCoverageFilter]}
                          />
                        )}
                      </div>

                      {/* Sun Glint */}
                      <div className="flex-col w-full">
                        <div
                          className={`flex items-center w-full cursor-pointer hover:bg-item-selected dark:hover:bg-neutral-800`}
                          onClick={() => {
                            if (isSunGlintFilterActive) {
                              setSunGlintFilter({
                                min: undefined,
                                max: undefined,
                              });
                            } else {
                              setSunGlintFilter({
                                min: 0,
                                max: 0,
                              });
                            }
                          }}
                        >
                          <div className="flex items-center w-full gap-4">
                            <FilterTitle
                              title={translate('datacosmos.filters.sunGlint')}
                              icon={'contrast'}
                              tooltipContent={translate(
                                'datacosmos.filters.detailedTooltips.sunGlint.description'
                              )}
                              className="text-sm font-normal"
                            />
                          </div>
                          <AnchorButton
                            icon={
                              isSunGlintFilterActive ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke={isDarkmode ? 'white' : 'currentColor'}
                                  strokeWidth={2}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  />
                                </svg>
                              ) : (
                                IconNames.ADD
                              )
                            }
                            minimal
                            small
                            data-testid="datacosmos-item-search-filters-sun-glint-add-remove"
                          />
                        </div>
                        {isSunGlintFilterActive && (
                          <RangeSlider
                            minValue={0}
                            maxValue={180}
                            numberOfHandles={2}
                            onChange={(values) => {
                              handleSunGlintChange(values);
                            }}
                            showValuesAboveHandles
                            step={0.1}
                            value={[
                              sunGlintFilter.min ?? 0,
                              sunGlintFilter.max ?? 180,
                            ]}
                          />
                        )}
                      </div>
                    </div>
                  </Accordion>
                  <Accordion
                    labelText={translate(
                      'datacosmos.filters.sections.dataSpecification'
                    )}
                    labelRenderer={labelRendererGenerator(
                      noOfActiveFilters.dataSpecification,
                      'data-specification'
                    )}
                    labelClassName="w-full"
                    isOpen={openSections.includes('dataSpecification')}
                    onToggle={(isOpen: boolean) =>
                      setOpenSections(
                        isOpen
                          ? ['advanced', 'dataSpecification']
                          : ['advanced']
                      )
                    }
                  >
                    <div className="p-2 flex flex-col gap-2">
                      {/* Asset type */}
                      <div>
                        <FilterTitle
                          title={translate('datacosmos.filters.assetType')}
                          icon={'media'}
                          tooltipContent={translate(
                            'datacosmos.filters.detailedTooltips.assetType.description'
                          )}
                        />

                        <MultiSelect
                          placeholder={translate(
                            'datacosmos.filters.placeholder'
                          )}
                          className={[Classes.FILL].join(' ')}
                          items={ASSET_TYPE}
                          tagRenderer={(tag) => tag?.label}
                          itemRenderer={renderItemGenerator(assetTypeFilter)}
                          onItemSelect={(item) =>
                            handleAssetTypeToggle(item?.id)
                          }
                          selectedItems={ASSET_TYPE.filter((x) =>
                            assetTypeFilter.includes(x.id)
                          )}
                          fill={true}
                          tagInputProps={{
                            onRemove: (_, i) =>
                              handleAssetTypeToggle(assetTypeFilter.at(i)),
                          }}
                        />
                      </div>

                      {/* Source */}
                      <div>
                        <FilterTitle
                          title={translate('datacosmos.filters.source.title')}
                          icon={'diagram-tree'}
                          tooltipContent={translate(
                            'datacosmos.filters.detailedTooltips.sources.description'
                          )}
                        />

                        <MultiSelect
                          placeholder={translate(
                            'datacosmos.filters.placeholder'
                          )}
                          className={[Classes.FILL].join(' ')}
                          items={SOURCE}
                          tagRenderer={(tag) => tag?.label}
                          itemRenderer={renderItemGenerator(sourceFilter)}
                          onItemSelect={(item) => handleSourceToggle(item?.id)}
                          selectedItems={SOURCE.filter((x) =>
                            sourceFilter.includes(x.id)
                          )}
                          fill={true}
                          tagInputProps={{
                            onRemove: (_, i) =>
                              handleSourceToggle(sourceFilter.at(i)),
                          }}
                        />
                      </div>

                      <div>
                        <FilterTitle
                          title={translate(
                            'datacosmos.filters.resolution.title'
                          )}
                          icon={'full-circle'}
                          tooltipContent={translate(
                            'datacosmos.filters.detailedTooltips.resolution.description'
                          )}
                        />

                        <MultiSelect
                          placeholder={translate(
                            'datacosmos.filters.placeholder'
                          )}
                          className={[Classes.FILL].join(' ')}
                          items={RESOLUTION}
                          tagRenderer={(tag) => tag?.label}
                          itemRenderer={renderItemGenerator(resolutionFilter)}
                          onItemSelect={(item) =>
                            handleResolutionToggle(item?.id)
                          }
                          selectedItems={RESOLUTION.filter((x) =>
                            resolutionFilter.includes(x.id)
                          )}
                          fill={true}
                          tagInputProps={{
                            onRemove: (_, i) =>
                              handleResolutionToggle(resolutionFilter.at(i)),
                          }}
                        />
                      </div>
                    </div>
                  </Accordion>
                  {canViewInternalFilters && (
                    <Accordion
                      labelText={translate(
                        'datacosmos.filters.sections.openCosmosInternal'
                      )}
                      labelRenderer={labelRendererGenerator(
                        noOfActiveFilters.internal,
                        'open-cosmos-internal'
                      )}
                      labelClassName="w-full"
                      isOpen={openSections.includes('openCosmosInternal')}
                      onToggle={(isOpen: boolean) =>
                        setOpenSections(
                          isOpen
                            ? ['advanced', 'openCosmosInternal']
                            : ['advanced']
                        )
                      }
                    >
                      <div className="p-2 flex flex-col gap-2">
                        <div>
                          <FilterTitle
                            title={translate(
                              'datacosmos.filters.collectionType.title'
                            )}
                            icon={'Folder'}
                            tooltipContent={translate(
                              'datacosmos.filters.detailedTooltips.collectionType.description'
                            )}
                          />

                          <MultiSelect
                            placeholder={translate(
                              'datacosmos.filters.placeholder'
                            )}
                            className={[Classes.FILL].join(' ')}
                            items={COLLECTION_TYPE}
                            tagRenderer={(tag) => tag?.label}
                            itemRenderer={renderItemGenerator(
                              collectionTypeFilter
                            )}
                            onItemSelect={(item) =>
                              handleCollectionTypeToggle(item?.id)
                            }
                            selectedItems={COLLECTION_TYPE.filter((x) =>
                              collectionTypeFilter?.includes(x?.id)
                            )}
                            fill={true}
                            tagInputProps={{
                              onRemove: (_, i) =>
                                handleCollectionTypeToggle(
                                  collectionTypeFilter?.at(i)
                                ),
                            }}
                          />
                        </div>

                        <div>
                          <FilterTitle
                            title={translate(
                              'datacosmos.filters.qaStatus.title'
                            )}
                            icon={'lab-test'}
                            tooltipContent={translate(
                              'datacosmos.filters.detailedTooltips.qaStatus.description'
                            )}
                          />

                          <MultiSelect
                            placeholder={translate(
                              'datacosmos.filters.placeholder'
                            )}
                            className={[Classes.FILL].join(' ')}
                            items={QA_STATUS}
                            tagRenderer={(tag) => tag?.label}
                            itemRenderer={renderItemGenerator(qaStatusFilter)}
                            onItemSelect={(item) =>
                              handleQaStatusToggle(item?.id)
                            }
                            selectedItems={QA_STATUS.filter((x) =>
                              qaStatusFilter?.includes(x?.id)
                            )}
                            fill={true}
                            tagInputProps={{
                              onRemove: (_, i) =>
                                handleQaStatusToggle(qaStatusFilter?.at(i)),
                            }}
                          />
                        </div>
                      </div>
                    </Accordion>
                  )}
                </div>
              </Accordion>
            </div>
            <div className="flex items-end justify-center h-full">
              {areFiltersApplied && (
                <Button
                  icon="Cross"
                  intent="warning"
                  iconPlacement="left"
                  isMinimal
                  size="base"
                  className="w-full justify-start"
                  onPress={clearAllFilters}
                >
                  <span className="text-center w-full text-sm">
                    {translate('datacosmos.filters.clearAll')}
                  </span>
                </Button>
              )}
            </div>
          </div>
        )}
      </MapCoveringModal>
    </div>
  );
};

export default Filters;
