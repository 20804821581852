import { getStorageFile } from '_api/storage/service';
import canvastotiff from 'datacosmos/utils/canvastotiff';
import domToImg from 'dom-to-image-more';
import conidaWatermark from 'public/images/datacosmos/conida-logo-98x98.png';
import { LOGO } from 'env';
import OCLogo from 'images/logo-monogram-black.svg';

export interface IScreenshot {
  mime: string;
  base64: string;
}

export const DEFAULT_VIEW_SCREENSHOT_SIZE = 600;

export const screenshotToString = (s: IScreenshot): string => {
  return `data:${s.mime}, ${s.base64}`;
};

export const screenshotLinkToUsableImage = async (sl: string) => {
  if (sl === '') {
    return undefined;
  }
  const { data: imgBlob } = await getStorageFile({ params: { path: sl } });

  if (!imgBlob) {
    return undefined;
  }

  const src = URL.createObjectURL(imgBlob);

  return src;
};

export const stringToScreenshot = (s: string): IScreenshot => {
  const parts = s.split(',');
  if (parts.length !== 2) {
    throw new Error(`Invalid screenshot string:\n${s}`);
  }
  return {
    mime: parts[0].replace('data:', ''),
    base64: parts[1],
  };
};

export type MimeType =
  | 'image/png'
  | 'image/jpeg'
  | 'image/gif'
  | 'image/tiff'
  | 'file/geojson'
  | 'application/pdf'
  | 'application/vnd.google-earth.kml+xml'
  | 'application/vnd.google-earth.kmz'
  | 'application/vnd.shp';

export type ScreenshotExtension =
  | '.png'
  | '.jpg'
  | '.gif'
  | '.tiff'
  | '.geojson'
  | '.pdf'
  | '.kml'
  | '.kmz'
  | '.shp';

export const screenshotMimeToExtension = (
  mime: MimeType
): ScreenshotExtension => {
  return `.${mime.split('/')[1]}` as ScreenshotExtension;
};

export const screenshot = (
  element: HTMLDivElement | HTMLElement | null | undefined,
  options: {
    size?: number;
    mimeType: MimeType;
    ignore?: string[] | string;
    scale?: number;
    includeWaterMark?: boolean;
  }
): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (element === null || element === undefined) {
      return reject('No element to screenshot');
    }

    const aspectRatio = element.clientWidth / element.clientHeight;
    const size = options.size ?? 0;
    let width = size;
    let height = size;

    if (element.clientWidth > element.clientHeight) {
      height = size / aspectRatio;
    } else {
      width = size * aspectRatio;
    }

    // Upscale the image to improve quality
    // Scale of 1 produces a very blurry image
    const scale = options.scale ?? 4;

    const shouldResize = Boolean(options.size);
    void domToImg
      .toCanvas(element, {
        height: element.clientHeight * scale,
        width: element.clientWidth * scale,
        style: {
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
        },
        copyDefaultStyles: false,
        filter: (node) => {
          const el = node as HTMLElement;

          if (!options.ignore) {
            return true;
          }

          if (!el.classList) {
            return true;
          }

          if (Array.isArray(options.ignore) && options.ignore.length === 0) {
            return true;
          }

          if (
            Array.isArray(options.ignore) &&
            [...el.classList].some((c) => options.ignore?.includes(c))
          ) {
            return false;
          }

          if (
            !Array.isArray(options.ignore) &&
            el.classList.contains(options.ignore)
          ) {
            return false;
          }

          return true;
        },
      })
      .then((canvas) => {
        // Add the watermark image based on options
        const watermarkImage = new Image();
        watermarkImage.src = LOGO ? conidaWatermark : OCLogo;
        watermarkImage.onload = function () {
          if (options.includeWaterMark) {
            const context = canvas.getContext('2d');
            if (!context) return;

            // Set the opacity of watermark
            context.globalAlpha = 0.65;
            context.drawImage(
              watermarkImage,
              canvas.width - watermarkImage.width,
              canvas.height - watermarkImage.height - 200
            );
          }
          if (options.mimeType === 'image/tiff') {
            //toDataURL is very slow with TIFF. Can be change if necessary
            canvastotiff.toObjectURL(canvas, (url: string) => {
              resolve(url);
            });
            return;
          }
          const rawBase64Image = canvas.toDataURL(options.mimeType);
          if (shouldResize) {
            const resizeImage = document.createElement('img');

            resizeImage.onload = function () {
              const imageCanvas = document.createElement('canvas');
              imageCanvas.width = width;
              imageCanvas.height = height;

              const context = imageCanvas.getContext('2d');
              if (!context) return;

              context.drawImage(this as CanvasImageSource, 0, 0, width, height);

              const base64Image = imageCanvas.toDataURL();
              imageCanvas.remove();
              resizeImage.remove();

              resolve(base64Image);
            };

            resizeImage.src = rawBase64Image;
          } else {
            resolve(rawBase64Image);
          }
        };
      });
  });
};

export const vectorMimeTypes: MimeType[] = [
  'file/geojson',
  'application/vnd.google-earth.kml+xml',
  'application/vnd.google-earth.kmz',
  'application/vnd.shp',
];
