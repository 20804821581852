import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  flexRender,
  CellContext,
  getSortedRowModel,
  SortingState,
  Header,
  Row,
  Cell,
} from "@tanstack/react-table";
import classNames from "classnames";
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  capitalizeTitle,
  deArrayify,
  getLeafColumnIds,
  mergeObjects,
} from "./helpers";
import Icon from "../../icons/Icon";
import { Button } from "..";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import DraggableHeader from "./DraggableHeader";
import DragAlongCell from "./DragAlongCell";

type Props<T> = {
  /**
   * Data to display in the table. Can be any array of any object.
   * The table will automatically generate columns based on the keys of the object.
   * If the object contains nested objects, the table will generate columns for the nested objects as well, containing the leaf keys of the nested object.
   *
   * NOTE: This **must** be a stable reference to avoid infinite re-renders, e.g using `useMemo`, `useState`, etc.
   */
  data: T[];
  /**
   * The keys to exclude from the data. This is useful for excluding keys that are not needed in the table.
   * By default, this is an empty array.
   */
  excludeDataKeys?: string[];
  /**
   * Function to render a cell in the table. Exposes the cell context and the header id
   * @param cellContext - The cell context; contains the cell value and other useful information. This is exposed from the @tanstack/react-table library
   * @param headerId - The id of the header that the cell belongs to. Useful for conditional rendering of certain cells.
   */
  cellRenderer: (
    cellContext: CellContext<T, unknown>,
    headerId: string
  ) => JSX.Element;
  /**
   * Whether to enable row selection or not
   * By default, this is false
   */
  enableRowSelection?: boolean;
  /**
   * Whether to enable multi-row selection or not
   */
  enableMultiRowSelection?: boolean;
  /**
   * Function to call when a row is selected. Exposes the selected row data
   * If no rows are selected, this will be undefined
   * If a single row is selected, this will be the selected row
   * If multiple rows are selected, this will be an array of the selected rows
   * @param rowData - The selected object from the row. If multiple rows are selected, this will be an array of objects from the selected rows.
   **/
  onRowSelect?: (rowData: T | T[] | undefined) => void;
  /**
   * The columns to hide. This is an array of strings that represent the column ids to hide
   */
  hiddenColumns?: string[];
  /**
   * Whether to show the full header title including the full path to the leaf node or not. By default, this is false.
   * If this is true, the header title will be the full path to the leaf node, e.g. `parent.child.leaf`
   * If this is false, the header title will be the leaf node, e.g. `leaf`
   */
  showFullHeaderTitle?: boolean;
  /**
   * Whether to capitalize the table headers or not. By default, this is false.
   */
  capitalizeTableHeaders?: boolean;

  /**
   * Controls the alignment of the header. By default, this is "center".
   */
  alignHeader?: "left" | "center" | "right";

  /**
   * The order of the columns. This is an array of strings that represent the column ids in the order they should be displayed.
   */
  columnOrder?: string[];

  /**
   * Whether to enable sorting or not. By default, this is false.
   */
  enableSorting?: boolean;
  /**
   * onColumnSort is a function that is called when the column order is changed.
   *
   * If this is provided, the table will render a drag handle on the header to allow the user to reorder the columns.
   * @param newOrder - The new order of the columns
   */
  onColumnReorder?: (newOrder: string[]) => void;
  /**
   * headersToExcludeFromReorder is an array of strings that represent the ids of headers that should not be reorderable. Drag handle will not be rendered for these headers.
   */
  headersToExcludeFromReorder?: string[];
  /**
   * subComponentRenderer renders a sub-component for each row in the table.
   * This is useful for rendering additional information for each row in the table.
   * @param props - The props to pass to the sub-component renderer. This contains the row data for the current row.
   * @returns The sub-component to render for each row in the table.
   */
  subComponentRenderer?: (row: Row<T>) => React.ReactElement;
  /**
   * getRowCanExpand is a function that determines whether a row can be expanded or not.
   *
   * This can be useful for conditionally expanding rows.
   * @param row - The row to determine if it can be expanded or not
   * @returns Whether the row can be expanded or not
   */
  getRowCanExpand?: (row: Row<T>) => boolean;
  /**
   * Columns to append to the table. This is an array of column configurations.
   *
   * Each column configuration contains the following properties:
   * - `cell`: The cell renderer for the column. This is a function that takes a cell context and returns a JSX element
   * - `id`: The id of the column. This is a string that represents the column id
   * - `header`: The header title of the column. This is a string that represents the header title
   *
   * The columns will be appended to the end of the table.
   */
  appendColumns?: ColumnConfig<T>[];
  /**
   * If true, the table will render with borderless styling. By default, this is false.
   */
  isMinimal?: boolean;
  /**
   * Key value pairs to remap the headers of the table to a different value. This is useful for renaming headers.
   *
   * For example, if you have a header called `oldHeader` and you want to rename it to `newHeader`, you can pass in the following object:
   *
   * ```tsx
   * headerRemap={{
   *  oldHeader: 'newHeader'
   * }}
   */
  headerRemap?: Record<string, string>;
  /**
   * Whether to show the sticky header or not. By default, this is false.
   * If this is true, the header will stick to the top of the table when scrolling
   */
  stickyHeader?: boolean;
  /**
   * alwaysIncludeKeys is an array of strings that represent the keys to always include in the table.
   *
   * The keys inside this array will always be included in the table, even if their parent keys are excluded.
   *
   * This is useful for including certain keys that are always needed in the table.
   */
  alwaysIncludeKeys?: string[];
};

export type ColumnConfig<T> = {
  cell: (p: CellContext<T, unknown>) => JSX.Element;
  id: string;
  header: string;
};

const Table = <
  T extends Record<
    string,
    string | number | boolean | T | Object | null | undefined
  >
>({
  excludeDataKeys = [],
  enableRowSelection = false,
  data: dataState,
  showFullHeaderTitle = false,
  capitalizeTableHeaders,
  columnOrder,
  enableSorting = false,
  subComponentRenderer,
  getRowCanExpand,
  isMinimal = false,
  alignHeader = "center",
  headerRemap,
  appendColumns,
  stickyHeader = false,
  onColumnReorder,
  headersToExcludeFromReorder,
  alwaysIncludeKeys = [],
  ...props
}: Props<T>) => {
  const columnHelper = createColumnHelper<T>();

  const getHeader = (uniqueId: string, key: string) => {
    let h: string = key;

    if (showFullHeaderTitle) {
      return uniqueId;
    }

    if (headerRemap && headerRemap[uniqueId]) {
      h = headerRemap[uniqueId];
    }

    if (capitalizeTableHeaders) {
      h = capitalizeTitle(h);
    }

    return h;
  };

  const getCols = (data: T, key: string = ""): ColumnConfig<T>[] => {
    return Object.entries(data ?? {})
      .flatMap(([k, value]) => {
        if (
          !Array.isArray(value) &&
          typeof value === "object" &&
          value !== null
        ) {
          return getCols(value as T, key !== "" ? `${key}.${k}` : k.toString());
        }

        const uniqueId = key !== "" ? `${key}.${k}` : (k.toString() as any);

        const header = getHeader(uniqueId, k);

        return columnHelper.accessor(uniqueId, {
          cell: (p) => props.cellRenderer(p, uniqueId),
          id: uniqueId,
          header: header,
        }) as ColumnConfig<T>;
      })
      .filter((c) => {
        const keyParts = c.id.split(".");

        const baseKey = keyParts[0];
        const isBaseExcluded = excludeDataKeys.includes(baseKey);

        const isExplicitlyIncluded =
          alwaysIncludeKeys.includes(c.id) ||
          keyParts.some((_, i) =>
            alwaysIncludeKeys.includes(keyParts.slice(0, i + 1).join("."))
          );

        return isExplicitlyIncluded || !isBaseExcluded;
      });
  };

  const expanderCol = useMemo(
    () =>
      ({
        id: "expander",
        header: "",
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <div className="w-full h-full flex items-center justify-center">
              <Button
                icon={row.getIsExpanded() ? "ChevronDown" : "ChevronRight"}
                isTransparent={true}
                {...{
                  onPress: row.getToggleExpandedHandler(),
                  style: { cursor: "pointer" },
                }}
                variant="square"
                isMinimal={true}
              />
            </div>
          ) : null;
        },
      } as ColumnConfig<T>),
    []
  );

  const defaultCols = useMemo(() => {
    const cols = [
      ...getCols(mergeObjects(dataState) as T),
      ...(appendColumns ?? []),
    ];
    if (getRowCanExpand) {
      return [expanderCol, ...cols];
    }
    return cols;
  }, [dataState, getRowCanExpand]);

  const getDefaultHiddenColsAsObj = useCallback(() => {
    const hiddenCols = props.hiddenColumns ?? [];
    return hiddenCols.reduce((acc, col) => {
      acc[col] = false;
      return acc;
    }, {} as { [key: string]: boolean });
  }, [props.hiddenColumns]);

  const [selectedRows, setSelectedRows] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [visibleColumns, setVisibleColumns] = useState(
    getDefaultHiddenColsAsObj()
  );

  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnOrderState, setColumnOrderState] = useState<string[]>(
    (columnOrder ?? []).concat(
      defaultCols.filter((c) => !columnOrder?.includes(c.id)).map((c) => c.id)
    )
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (headersToExcludeFromReorder?.includes(over?.id as string)) {
      return;
    }

    if (active && over && active.id !== over.id) {
      setColumnOrderState((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id as string);
        const newIndex = columnOrder.indexOf(over.id as string);

        const newOrder = arrayMove(columnOrder, oldIndex, newIndex);
        onColumnReorder?.(newOrder);
        return newOrder;
      });
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const table = useReactTable({
    state: {
      rowSelection: selectedRows,
      columnVisibility: visibleColumns,
      columnOrder: columnOrderState,
      sorting,
    },
    onSortingChange: setSorting,
    onRowSelectionChange: setSelectedRows,
    onColumnVisibilityChange: setVisibleColumns,
    getSortedRowModel: getSortedRowModel(),
    data: dataState,
    columns: defaultCols,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: enableRowSelection,
    enableMultiRowSelection: props.enableMultiRowSelection ?? false,
    enableSorting,
    getRowCanExpand,
    manualPagination: true,
  });

  useEffect(() => {
    const selectedRowsData = Object.keys(selectedRows).map(
      (k) => table.getRowModel().rows[Number(k)].original as T
    );

    if (selectedRowsData.length === 0) {
      props.onRowSelect?.(undefined);
    } else if (selectedRowsData.length === 1) {
      props.onRowSelect?.(selectedRowsData[0]);
    } else {
      props.onRowSelect?.(selectedRowsData);
    }
  }, [selectedRows]);

  useEffect(() => {
    setVisibleColumns(getDefaultHiddenColsAsObj());
  }, [getDefaultHiddenColsAsObj]);

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <table className="text-left w-full relative border-collapse">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className={classNames(
                "h-8 bg-header dark:bg-header-dark min-w-max p-1 ",
                {
                  "cursor-pointer": enableSorting,
                }
              )}
            >
              <SortableContext
                items={columnOrder ?? []}
                strategy={horizontalListSortingStrategy}
              >
                {headerGroup.headers.map((header) => (
                  <DraggableHeader<T>
                    isReorderable={
                      onColumnReorder &&
                      !headersToExcludeFromReorder?.includes(header.id)
                    }
                    isMinimal={isMinimal}
                    isSticky={stickyHeader}
                    alignHeader={alignHeader}
                    header={header}
                  />
                ))}
              </SortableContext>
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <>
              <tr
                key={row.id}
                className={classNames(
                  "even:bg-item bg-item-dark-contrast dark:even:bg-item-dark dark:bg-item-contrast h-8",
                  "hover:bg-item-hover dark:hover:bg-item-dark-hover",
                  {
                    "bg-item-selected even:bg-item-selected dark:even:bg-item-dark-selected dark:bg-item-dark-selected":
                      row.getIsSelected(),
                  }
                )}
                onClick={(e) => {
                  row.getToggleSelectedHandler()(e);
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <SortableContext
                    key={cell.id}
                    items={columnOrderState}
                    strategy={horizontalListSortingStrategy}
                  >
                    <DragAlongCell<T>
                      key={cell.id}
                      cell={cell}
                      isMinimal={isMinimal}
                      isReorderable={
                        onColumnReorder &&
                        !headersToExcludeFromReorder?.includes(cell.column.id)
                      }
                      isSelectable={enableRowSelection}
                    />
                  </SortableContext>
                ))}
              </tr>
              {row.getIsExpanded() && (
                <tr>
                  <td colSpan={row.getVisibleCells().length}>
                    {subComponentRenderer?.(row)}
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>
    </DndContext>
  );
};

export { Table, getLeafColumnIds, deArrayify };
export type { CellContext };
