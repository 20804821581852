import { useEffect, useRef, useCallback } from 'react';
import type L from 'leaflet';
import mapLayers from 'datacosmos/utils/map-layers';
import { Button } from 'opencosmos-ui';

type MiniMapProps = {
  lat?: number;
  lng?: number;
  rightMenuOpen: boolean;
  handleSetCoordinates: (lat: number, lng: number) => void;
  isMinimapShown: boolean;
  setIsMinimapShown: (val: boolean) => void;
  isMinimal: boolean;
  isDarkmode: boolean;
};

export const MiniMap = ({
  lat,
  lng,
  rightMenuOpen,
  handleSetCoordinates,
  isMinimapShown,
  setIsMinimapShown,
  isMinimal,
  isDarkmode,
}: MiniMapProps) => {
  const container = useRef<HTMLDivElement>(null);
  const map = useRef<L.DrawMap | null>(null);
  const targetLines = useRef<L.Polyline | null>(null);

  const setCrosshair = useCallback(
    (latitude: number | undefined, longitude: number | undefined) => {
      if (!targetLines.current || !latitude || !longitude) return;
      if (isNaN(latitude) || isNaN(longitude)) return;

      targetLines.current.setLatLngs([
        [
          [-90, longitude],
          [90, longitude],
        ],
        [
          [latitude, -220],
          [latitude, 220],
        ],
      ]);
    },
    []
  );

  useEffect(() => {
    if (!container.current) return;

    map.current = (window.L as typeof L)
      .map(container.current, {
        center: [0, 0],
        zoomControl: false,
        attributionControl: false,
        dragging: false,
        touchZoom: false,
        scrollWheelZoom: false,
        doubleClickZoom: false,
        boxZoom: false,
        maxBounds: (window.L as typeof L).latLngBounds([-90, -180], [90, 180]),
      })
      .fitBounds((window.L as typeof L).latLngBounds([-90, -180], [90, 180]));

    map.current.addLayer(
      (window.L as typeof L).tileLayer(
        isDarkmode ? mapLayers[1].url : mapLayers[0].url
      )
    );

    map.current.on('click', (event) => {
      handleSetCoordinates(event.latlng.lat, event.latlng.lng);
      setCrosshair(event.latlng.lat, event.latlng.lng);
    });

    targetLines.current = (window.L as typeof L)
      .polyline([], { color: '#1f3b45', weight: 0.5 })
      .addTo(map.current);

    return () => {
      map.current?.remove();
    };
  }, [handleSetCoordinates, isDarkmode, setCrosshair]);

  useEffect(() => {
    setCrosshair(lat, lng);
  }, [lat, lng, setCrosshair]);

  let leftButton = isMinimal ? '75px' : '4px';
  let rightButton = isMinimal ? '8px' : '80px';
  if (rightMenuOpen) {
    leftButton = '-385px';
    rightButton = '465px';
  }

  return (
    <div
      className="minmap-container"
      style={{ display: isMinimapShown ? 'block' : 'none' }}
    >
      <Button
        icon="ChevronRightDouble"
        className="z-10 absolute bottom-0 stroke-item-contrast dark:stroke-item-dark-contrast"
        size={'lg'}
        isMinimal
        isTransparent
        onPress={() => {
          setIsMinimapShown(false);
        }}
        style={{ left: leftButton }}
      />
      <div
        id="minimap"
        ref={container}
        style={{
          position: 'fixed',
          right: rightButton,
        }}
      ></div>
    </div>
  );
};
